/*--------------------------------------------
 header
---------------------------------------------*/

.header {
  @include absolute($top: 0, $left: 0);

  // active
  &--active {
    @include fixed($top: 0, $left: 0);
    background-color: $white;

    .header__logo-link {
      span {
        @include background-image("logo-b.svg");
      }
    }
    .header__nav-item {
      &:before {
        @include screen-tb {
          @include background-image("nav-bar-b.svg");
        }
      }
    }

    .header__nav-link {
      @include screen-tb {
        color: $black;
      }
      &:hover{
        opacity: .5;
      }
    }
  }

  &--page {
    .header__logo-link {
      span {
        @include background-image("logo-b.svg");
      }
    }
    .header__nav-item {
      &:before {
        @include screen-tb {
          @include background-image("nav-bar-b.svg");
        }
      }
    }
    .header__nav-link {
      @include screen-tb {
        color: $black;
      }
      &:hover{
        opacity: .5;
      }
    }
  }

  &__container {
    padding: 0 0 0 2rem;

    @include screen-tb {
      padding: 0 0 0 1.5rem;
    }

    @include screen-pc {
      padding: 0 0 0 2.5rem;
    }
  }
  &__flex-box {
    justify-content: space-between;
  }
  &__logo {
  }

  &__logo-link {
    font-size: 0;
    text-indent: -9999px;
    width: 14.6rem;
    height: 6rem;
    position: relative;
    display: block;

    &:hover{
      opacity: .8;
    }

    @include screen-tb {
      width: calc(22.8rem * 0.6);
      height: calc(8rem * 0.6);
    }

    @include screen-pc {
      width: 22.8rem;
      height: 8rem;
    }

    span {
      @include background-image("logo.svg");
      @include center(xy);
      transform: translate(-50%, -40%);
      width: 14.6rem;
      height: 3rem;

      @include screen-tb {
        width: calc(22.8rem * 0.6);
        height: calc(4.7rem * 0.6);
      }

      @include screen-pc {
        width: 22.8rem;
        height: 4.7rem;
      }
    }
  }

  &__nav {
    @include screen-tb {
      margin-left: auto;
    }
  }

  &__nav-list {
    transition: opacity 0.5s $gnav-easing, visibility 0.5s $gnav-easing;
    transform: translate3d(0, 0, 0);
    will-change: visibility, opacity;

    @include screen-tb {
      padding-right: calc(10.8rem * 0.6);
      position: relative;
    }

    @include screen-pc {
      padding-right: 10.8rem;
    }

    .menu-open & {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__nav-item {
    position: relative;

    &:before {
      @include screen-tb {
        @include background-image("nav-bar.svg");
        @include absolute($top: 50%, $left: 0);
        transform: translateY(-50%);
        width: calc(1.6rem * 0.6);
        height: calc(4.3rem * 0.6);
        content: "";
        display: block;
      }

      @include screen-pc {
        width: 1.6rem;
        height: 4.3rem;
      }
    }
    // margin-left: -.8rem;
  }

  &__nav-link {
    @include screen-tb {
      color: $white;
      height: calc(8rem * 0.6);
      line-height: calc(8rem * 0.6);
      padding: 0 calc(3.1rem * 0.6) 0 calc(4.7rem * 0.6);
      display: block;
      font-size: calc(1.6rem * 0.6);
      font-size: calc(16px * 0.6);
    }

    @include screen-pc {
      height: 8rem;
      line-height: 8rem;
      padding: 0 3.1rem 0 4.7rem;
      font-size: 1.6rem;
      font-size: 16px;
    }

    &:hover{
      opacity: .5;
    }
  }

  &__nav-button {
    @include absolute($top: 0, $right: 0);
    @include background-image("nav-button.svg");
    width: 8.1rem;
    height: 6rem;
    cursor: pointer;

    @include screen-tb {
      width: calc(10.8rem * 0.6);
      height: calc(8rem * 0.6);
    }

    @include screen-pc {
      width: 10.8rem;
      height: 8rem;
    }
  }
  &__nav-button-line {
    @include absolute($top: 0, $bottom: 0, $left: 0, $right: -2.1rem);
    display: block;
    width: 2.4rem;
    height: 0.2rem;
    margin: auto;
    background: #fff;
    transition: 0.5s $nav-button-easing;

    &:nth-of-type(1) {
      transform: translateY(-0.6rem);

      .menu-open & {
        transform: rotate(45deg);
      }
    }
    &:nth-of-type(2) {
      .menu-open & {
        opacity: 0;
      }
    }
    &:nth-of-type(3) {
      transform: translateY(0.6rem);

      .menu-open & {
        transform: rotate(-45deg);
      }
    }
  }
}
