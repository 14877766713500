.fix-menu {
  @extend .page;
  @include fixed($top: 0, $left: 0);
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.9);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s $gnav-easing, visibility 0.5s $gnav-easing;
  transform: translate3d(0, 0, 0);
  will-change: visibility, opacity;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  @include screen-tb {
    overflow: auto;
  }

  .menu-open & {
    opacity: 1;
    visibility: visible;
  }

  &__wrap {
    display: block;
    padding: 12rem 0;
    height: auto;
    min-height: 100vh;

    @include screen-tb {
      // padding: 18rem 0 0 0;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      padding: 0;
    }
  }

  &__container {
    padding: 0 2rem;
    max-width: 96rem;
    
    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl {
    text-align: center;

    span {
      color: $white;
      letter-spacing: 0.05em;
      font-size: 4rem;

      &:nth-of-type(1) {
        color: $red;
      }

      @include screen-tb {
        font-size: calc(5.9rem * 0.6);
        margin-bottom: calc(1rem * 0.6);
      }

      @include screen-pc {
        font-size: 5.9rem;
        margin-bottom: 1rem;
      }
    }
  }

  &__home-link {
    text-align: center;
    color: $white;
    display: block;
    margin: 0 auto;
    background-color: rgba($color: #fff, $alpha: 0.2);
    font-size: 1.2rem;
    letter-spacing: 0.25em;
    width: calc(19rem * 0.6);
    height: calc(2.6rem * 0.6);
    line-height: calc(2.6rem * 0.6);
    margin-bottom: calc(3.5rem * 0.6);

    &:hover{
      opacity: .8;
    }

    @include screen-tb {
      text-align: center;
      color: $white;
      width: calc(19rem * 0.6);
      height: calc(2.6rem * 0.6);
      line-height: calc(2.6rem * 0.6);
      margin-bottom: calc(3.5rem * 0.6);
    }

    @include screen-pc {
      width: 19rem;
      height: 2.6rem;
      line-height: 2.6rem;
      margin-bottom: 3.5rem;
    }
  }

  &__flex {
    flex-direction: column;

    @include screen-tb {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: calc(5.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 5.5rem;
    }
  }

  &__left,
  &__right {
    width: 100%;

    @include screen-tb {
      width: calc(50% - calc(2rem * 0.6));
    }

    @include screen-pc {
      width: calc(50% - 2rem);
    }
  }

  // left
  &__left-list {
    border-top: 1px solid rgba($color: #fff, $alpha: .3);
  }

  &__left-item {
    border-bottom: 1px solid rgba($color: #fff, $alpha: .3);
    position: relative;

    &:after {
      @include absolute($top: 50%, $right: 1rem);
      @include background-image("arrow-s.svg");
      transform: translateY(-50%);
      content: "";
      display: block;
      width: calc(1.9rem * 0.6);
      height: calc(1.4rem * 0.6);

      @include screen-tb {
        width: calc(1.9rem * 0.6);
        height: calc(1.4rem * 0.6);
      }

      @include screen-pc {
        width: 1.9rem;
        height: 1.4rem;
      }
    }

    &:nth-child(2) {
      &:before {
        @include absolute($top: 50%, $left: 2rem);
        transform: translateY(-50%);
        width: 1.3rem;
        height: 1px;
        content: "";
        display: block;
        background-color: $white;

        @include screen-tb {
          @include absolute($top: 50%, $left: calc(2rem * .6));
          width: calc(1.3rem * .6);
        }

        @include screen-pc {
          @include absolute($top: 50%, $left: 2rem);
          width: 1.3rem;
        }
      }

      &:after {
        @include absolute($top: 50%, $right: 1rem);
        @include background-image("ico-link.svg");
        transform: translateY(-50%);
        content: "";
        display: block;
        width: calc(1.8rem * 0.6);
        height: calc(1.8rem * 0.6);

        @include screen-tb {
          width: calc(1.8rem * 0.6);
          height: calc(1.8rem * 0.6);
        }

        @include screen-pc {
          width: 1.8rem;
          height: 1.8rem;
        }
      }

      .fix-menu__link {
        padding: calc(2.15rem * 0.8) calc(2.15rem * 0.8) calc(2.15rem * 0.8) calc(6rem * 0.6);

        @include screen-tb {
          padding: calc(2.15rem * 0.6) calc(2.15rem * 0.6) calc(2.15rem * 0.6) calc(4rem * 0.6);
        }

        @include screen-pc {
          padding: 2.15rem 2.15rem 2.15rem 4rem;
        }
      }
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {

      &:before {
        @include absolute($top: 50%, $left: 2rem);
        transform: translateY(-50%);
        width: 1.3rem;
        height: 1px;
        content: "";
        display: block;
        background-color: $white;

        @include screen-tb {
          @include absolute($top: 50%, $left: calc(2rem * .6));
          width: calc(1.3rem * .6);
        }

        @include screen-pc {
          @include absolute($top: 50%, $left: 2rem);
          width: 1.3rem;
        }
      }
      .fix-menu__link {
        padding: calc(2.15rem * 0.8) calc(2.15rem * 0.8) calc(2.15rem * 0.8) calc(6rem * 0.6);

        @include screen-tb {
          padding: calc(2.15rem * 0.6) calc(2.15rem * 0.6) calc(2.15rem * 0.6) calc(4rem * 0.6);
        }

        @include screen-pc {
          padding: 2.15rem 2.15rem 2.15rem 4rem;
        }
      }
    }

    &:nth-child(7) {
      border-bottom: 0;

      @include screen-tb {
        border-bottom: 1px solid rgba($color: #fff, $alpha: .3);
      }
    }
  }

  // right
  &__right-list {
    border-top: 1px solid rgba($color: #fff, $alpha: .3);
    margin-bottom: 4rem;

    @include screen-tb {
      margin-bottom: calc(3rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 3rem;
    }
  }

  &__right-item {
    border-bottom: 1px solid rgba($color: #fff, $alpha: .3);
    position: relative;

    &:after {
      @include absolute($top: 50%, $right: 1rem);
      @include background-image("arrow-s.svg");
      transform: translateY(-50%);
      content: "";
      display: block;
      width: calc(1.9rem * 0.6);
      height: calc(1.4rem * 0.6);

      @include screen-tb {
        width: calc(1.9rem * 0.6);
        height: calc(1.4rem * 0.6);
      }

      @include screen-pc {
        width: 1.9rem;
        height: 1.4rem;
      }
    }
  }

  &__right-banner {
    justify-content: space-between;
    margin-bottom: 4rem;


    @include screen-tb {
      margin-bottom: 0;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__right-banner-magazine,
  &__right-banner-shop {
    display: block;
    width: calc(50% - calc(0.5rem * 0.6));

    &:hover{
      opacity: .8;
    }

    @include screen-tb {
      width: calc(50% - calc(0.5rem * 0.6));
    }
    @include screen-pc {
      width: calc(50% - 0.5rem);
    }
  }

  //common
  &__link {
    color: $white;
    line-height: 1;
    display: block;
    font-size: 1.5rem;
    padding: calc(2.15rem * 0.8) calc(2.15rem * 0.8);

    &:hover{
      opacity: .5;
    }

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
      padding: calc(2.15rem * 0.6) calc(2.15rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.6rem;
      padding: 2.15rem 2.15rem;
    }
  }

  //sns
  &__sns-list {
    justify-content: center;
  }

  &__sns-item {
    margin: 0 0.5rem;
    width: calc(33.3% - 1rem);

    @include screen-tb {
      width: auto;
    }
  }

  &__sns-link {
    img {
      display: block;
      width: 100%;
      height: auto;

      &:hover{
        opacity: .8;
      }
    }
  }
}
