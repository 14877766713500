/*--------------------------------------------
 found > mixin
---------------------------------------------*/

/*--------------------------------------------
メディアクエリ
参考 - https://designsupply-web.com/media/knowledgeside/2124/

呼び出し方
@include screen-mobile {}
@include screen-tablet {}
---------------------------------------------*/

// 768 ~
@mixin screen-tb {
  @media screen and (min-width: $tb) {
    @content;
  }
}

// 1280 ~
@mixin screen-pc {
  @media screen and (min-width: $pc) {
    @content;
  }
}

/*--------------------------------------------
テキスト要素のline-heightを取り除く
参考 - https://coliss.com/articles/build-websites/operation/css/simple-css-remove-top-space.html
---------------------------------------------*/

@mixin lhCrop($line-height) {
  &::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }

  &::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }
}

/*--------------------------------------------
absolute : position:absolute; および top / right / bottom / left の設定を一括設定

呼び出し方

p{
  @include absolute(0,0,0,0);
}
p.vertical{
  @include absolute($top:0, $bottom:0);
}

参考 - https://kaminarimagazine.com/
---------------------------------------------*/

@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;
  @if $top != null {
    top: $top;
  }
  @if $right != null {
    right: $right;
  }
  @if $bottom != null {
    bottom: $bottom;
  }
  @if $left != null {
    left: $left;
  }
}

@mixin fixed($top: null, $right: null, $bottom: null, $left: null) {
  position: fixed;
  @if $top != null {
    top: $top;
  }
  @if $right != null {
    right: $right;
  }
  @if $bottom != null {
    bottom: $bottom;
  }
  @if $left != null {
    left: $left;
  }
}

/*--------------------------------------------
親要素に対して中央寄せ
引数に x, y, xy いずれかを与える
参考 - https://web-guided.com/376/
---------------------------------------------*/

@mixin center($direction) {
  position: absolute;
  @if $direction==xy {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if $direction==x {
    left: 50%;
    transform: translateX(-50%);
  } @else if $direction==y {
    top: 50%;
    transform: translateY(-50%);
  }
}

/*--------------------------------------------
background-image
参考 - https://web-guided.com/376/
---------------------------------------------*/

$path-images: "../img/" !default;

@mixin background-image($fileName) {
  background-image: url($path-images + $fileName);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  @content;
}

/*--------------------------------------------
ブロックの縦横比を設定

呼び出し方
p{
  @include aspect-ratio(1200px, 400px);
}
p.bg{
  @include aspect-ratio(1200px, 400px, "../images/bg.png");
}

参考 - https://kaminarimagazine.com/
---------------------------------------------*/

@mixin aspect-ratio($width, $height, $image_path: null) {
  display: block;
  width: 100%;
  height: auto;
  padding-top: ($height / $width) * 100%;

  @if $image_path != null {
    background: url($image_path) 50% 50% no-repeat;
    background-size: cover;
  }
}

/*--------------------------------------------
animation
参考 - codemafia - udemy
---------------------------------------------*/

@mixin animation(
  $name,
  $duration: 1s,
  $timing-function: ease,
  $delay: 0s,
  $iteration-count: 1,
  $direction: normal,
  $fill-mode: forwards
) {
  animation: {
    name: $name;
    duration: $duration;
    timing-function: $timing-function;
    delay: $delay;
    iteration-count: $iteration-count;
    direction: $direction;
    fill-mode: $fill-mode;
  }
}
