// news-archive-ttl
.news-archive-ttl {
  &__wrap {
    padding: 12.5rem 0 0;

    @include screen-tb {
      padding: calc(20rem * 0.6) 0 0;
    }

    @include screen-pc {
      padding: 20rem 0 0;
    }
  }
  &__container {
    max-width: 128rem;

    @include screen-tb {
      padding: 0 2rem;
    }

    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl-area {
    margin-bottom: 2rem;

    @include screen-tb {
      margin-bottom: calc(3.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 3.5rem;
    }
  }
  &__ttl-en {
    letter-spacing: 0;

    span {
      letter-spacing: 0;

      &:nth-of-type(1) {
        color: $red;
      }
    }
  }

  &__ttl-jp {
    margin-bottom: 2rem;

    @include screen-tb {
      margin-bottom: calc(2.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 2.5rem;
    }
  }
}

// news-archive-select
.news-archive-select {
  margin-bottom: 3rem;

  @include screen-tb {
    margin-bottom: calc(3.5rem * 0.6);
  }

  @include screen-pc {
    margin-bottom: 3.6rem;
  }
  &__wrap {
  }
  &__container {
    max-width: 64rem;
  }
  &__search {
    width: 27.7rem;
    border-radius: 2.5rem;
    height: 5rem;
    border: 2px solid $black;
    margin: 0 auto;
    position: relative;

    @include screen-tb {
      width: 100%;
      border-radius: 3rem;
      height: 6rem;
    }
  }
  &__search-button {
    @include center(xy);
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: calc(5rem - 4px);
    display: block;
    text-align: center;
    z-index: 2;
    background-color: $white;
    border-radius: 2.5rem;
    cursor: pointer;

    @include screen-tb {
      border-radius: 3rem;
      line-height: calc(6rem - 4px);
    }

    &:after {
      @include absolute($top: 50%, $right: 2.5rem);
      content: "";
      display: block;
      background-image: url("../img/arrow-search.svg");
      transform: translateY(-50%);
      width: 1.8rem;
      height: 1.1rem;
      transition: transform 0.3s $gnav-easing;
    }

    &.is-active {
      &:after {
        transform: translateY(-50%) scale(1, -1);
      }
    }
  }
  &__search-list {
    width: calc(100% + 4px);
    height: auto;
    max-height: 20rem;
    border-radius: 0 0 3rem 3rem;
    border: 2px solid $black;
    margin: 2.5rem 0 0 -2px;
    padding: 2.5rem 0 1.5rem 0;
    background-color: $white;
    position: relative;
    z-index: 1;

    // scroll

    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    // カルーセル用
    display: none;
    opacity: 0;
    transition: opacity 0.3s $gnav-easing;

    @include screen-tb {
      margin: 3rem 0 0 -2px;
      padding: 3rem 0 calc(2rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 3rem 0 2rem 0;
    }

    &.is-open {
      display: block;
      opacity: 1;
      animation: fade-in 0.3s $gnav-easing 0s;
    }
  }
  &__search-link {
    display: block;
    padding: 1rem 2rem;
    text-align: center;

    &:hover {
      opacity: 0.5;
    }

    @include screen-tb {
      padding: calc(2rem * 0.6) calc(2rem * 0.6);
    }

    @include screen-pc {
      padding: 1rem 2rem;
    }
  }
}

// news-archive
.news-archive {
  &__wrap {
    padding-bottom: 7rem;

    @include screen-tb {
      padding-bottom: calc(20rem * 0.6);
    }

    @include screen-pc {
      padding-bottom: 20rem;
    }
  }
  &__container {
    max-width: 96rem;

    @include screen-tb {
      padding: 0 2rem;
    }

    @include screen-pc {
      padding: 0;
    }
  }

  &__list {
    border-top: 1px solid $gray;
    margin-bottom: 4rem;

    @include screen-tb {
      margin-bottom: calc(6rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 6rem;
    }
  }
  &__item {
    border-bottom: 1px solid $gray;
    padding: 0 2rem;

    @include screen-tb {
      padding: 0;
    }
  }

  &__link {
    padding: 1.6rem 0;
    display: block;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      padding: calc(2.9rem * 0.6) calc(3.5rem * 0.6);
      display: flex;
    }

    @include screen-pc {
      padding: 2.9rem 3.5rem;
    }
  }

  &__date,
  &__cat,
  &__item-ttl {
    font-size: 1.2rem;

    @include screen-tb {
      font-size: calc(1.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.5rem;
    }
  }

  &__date {
    flex-shrink: 0;
    margin-right: 1rem;
    display: inline-block;

    @include screen-tb {
      margin-right: calc(1rem * 0.6);
    }

    @include screen-pc {
      margin-right: 1rem;
    }
  }

  &__cat {
    background-color: $blue;
    color: $white;
    flex-shrink: 0;
    text-align: center;
    letter-spacing: 0;
    display: inline-block;
    padding: 0.4rem 1rem;
    line-height: 1;
    margin-bottom: 0.5rem;
    min-width: 9.2rem;

    @include screen-tb {
      min-width: calc(11rem * 0.6);
      margin: 0 calc(1.5rem * 0.6);
      padding: calc(0.2rem * 0.6) calc(1rem * 0.6);
      max-height: calc(2.5rem * 0.6);
    }

    @include screen-pc {
      min-width: 11rem;
      margin: 0 1.5rem;
      padding: 0.2rem 1rem;
      max-height: 2.5rem;
    }
  }

  &__item-ttl {
    font-size: 1.5rem;
    display: block;
    @include screen-tb {
      @include screen-tb {
        font-size: calc(1.5rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.5rem;
      }
    }
  }

  &__pager {
    max-width: 32rem;
    margin: 0 auto;

    @include screen-tb {
      max-width: calc(60rem * 0.6);
    }
    @include screen-pc {
      max-width: 60rem;
    }

    .wp-pagenavi {
      text-align: center;
      position: relative;

      .pages,
      .first,
      .last {
        display: none;
      }

      a,
      span {
        display: inline-block;
        width: 3.6rem;
        height: 3.6rem;
        line-height: 3rem;

        // @include screen-tb {
        //   width: 3.2rem;
        //   height: 3.2rem;
        //   line-height: 2.6rem;
        // }
      }
      a.smaller,
      a.larger,
      span.current {
        background-color: #a7a3a1;
        border: none;
        margin: 0.5rem;
        color: $white;

        // @include screen-tb {
        //   margin: 0 0.2rem;
        // }
      }
      a.previouspostslink {
        @include background-image("prev.svg");
        @include absolute($top: 50%, $left: -2.5rem);
        transform: translateY(-50%);
        text-indent: -9999px;

        // @include screen-tb {
        //   position: relative;
        //   top: 1px;
        //   transform: translateY(0);
        //   left: auto;
        // }
      }
      a.nextpostslink {
        @include background-image("next.svg");
        @include absolute($top: 50%, $right: -2.5rem);
        transform: translateY(-50%);
        text-indent: -9999px;

        // @include screen-tb {
        //   position: relative;
        //   top: 1px;
        //   transform: translateY(0);
        //   left: auto;
        //   right: auto;
        // }
      }
    }
  }
}

// news_single-ttl
.news_single-ttl {
  &__wrap {
    padding: 12.6rem 0 0;

    @include screen-tb {
      padding: calc(20rem * 0.6) 0 0;
    }

    @include screen-pc {
      padding: 20rem 0 0;
    }
  }
  &__container {
    max-width: 64rem;
  }

  &__ttl-area {
    border-bottom: 1px solid $black;
    margin-bottom: 2rem;

    @include screen-tb {
      margin-bottom: calc(5rem * 0.6);
      padding-bottom: calc(3rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 5rem;
      padding-bottom: 3rem;
    }
  }
  &__ttl-en {
    letter-spacing: 0;

    span {
      letter-spacing: 0;

      &:nth-of-type(1) {
        color: $red;
      }
    }
  }

  &__ttl-jp {
    margin-bottom: 2rem;

    @include screen-tb {
      margin-bottom: calc(2.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 2.5rem;
    }
  }
}

// news_single
.news_single {
  &__wrap {
    padding: 1rem 0 11rem;

    @include screen-tb {
      padding: 0 0 calc(22rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 0 0 22rem 0;
    }
  }
  &__container {
    max-width: 64rem;
    padding: 0 2rem;
    @include screen-tb {
      padding: 0;
    }
  }
  &__date-cat {
    align-items: center;
    margin-bottom: 1rem;

    @include screen-tb {
      margin-bottom: calc(1rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 1rem;
    }
  }
  &__date {
    font-size: 1.4rem;
    margin-right: 2rem;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
      margin-right: calc(2.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.6rem;
      margin-right: 2.5rem;
    }
  }

  &__cat {
    background-color: $blue;
    color: $white;
    line-height: 1;
    font-size: 1.2rem;
    padding: 0.4rem 1rem;

    @include screen-tb {
      padding: calc(0.4rem * 0.6) calc(1rem * 0.6);
      font-size: calc(1.4rem * 0.6);
    }

    @include screen-pc {
      padding: 0.4rem 1rem;
      font-size: 1.4rem;
    }
  }

  &__ttl {
    line-height: 1.4;
    font-size: 2.4rem;
    margin-bottom: 2rem;

    @include screen-tb {
      font-size: calc(3rem * 0.6);
      margin-bottom: calc(3rem * 0.6);
    }

    @include screen-pc {
      font-size: 3rem;
      margin-bottom: 3rem;
    }
  }

  &__cont {
    font-size: 1.4rem;
    margin-bottom: 4.5rem;

    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
      margin-bottom: calc(11.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
      margin-bottom: 11.5rem;
    }

    p {
      line-height: 2;
      margin-bottom: 1.5rem;

      @include screen-tb {
        margin-bottom: calc(1.5rem * 0.6);
      }

      @include screen-pc {
        margin-bottom: 1.5rem;
      }
    }

    blockquote,
    em {
      font-style: italic;
    }

    a {
      color: lightseagreen;
    }

    img {
      max-width: 100%;
      height: auto;
      display: block;

      @include screen-tb {
        margin: calc(3rem * 0.6) 0;
      }

      @include screen-pc {
        margin: 3rem 0;
      }
    }

    iframe {
      max-width: 100%;
    }
  }

  &__back {
    width: 100%;
    height: 5rem;
    border: 1px solid $black;
    text-align: center;
    display: block;
    line-height: 4.8rem;
    position: relative;
    font-size: 1.4rem;

    @include screen-tb {
      height: calc(6rem * 0.6);
      line-height: calc(5.8rem * 0.6);
      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      height: 6rem;
      line-height: 5.8rem;
      font-size: 1.6rem;
    }

    &:before {
      @include background-image("arrow-back.svg");
      @include absolute($top: 50%, $left: 3rem);
      transform: translateY(-50%);
      content: "";
      display: block;
      width: 1.8rem;
      height: 1.3rem;

      @include screen-tb {
        @include absolute($top: 50%, $left: calc(3rem * 0.6));
        width: calc(1.8rem * 0.6);
        height: calc(1.3rem * 0.6);
      }

      @include screen-pc {
        @include absolute($top: 50%, $left: 3rem);
        width: 1.8rem;
        height: 1.3rem;
      }
    }
  }
}
