@keyframes zoomUp {
  0% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes sliderPagination {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-chars {
  0% {
    opacity: 0;
    transform: translateY(100.5%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide1 {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slide2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
