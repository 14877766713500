.footer {
  width: 100%;
  height: 47.3rem;

  @include screen-tb {
    height: calc(44rem * 0.6);
  }

  @include screen-pc {
    height: 44rem;
  }

  &__wrap {
    @include background-image("footer-bg-sp.jpg");
    height: 100%;
    padding: 8rem 0;

    @include screen-tb {
      @include background-image("footer-bg.jpg");
      padding: calc(7.5rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 7.5rem 0;
    }
  }

  &__container {
  }

  &__logo {
    margin-bottom: 1.5rem;

    @include screen-tb {
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 2rem;
    }
  }
  &__logo-link {
    picture {
      img {
        display: block;
        width: 22.8rem;
        height: 4.7rem;
        margin: 0 auto;

        &:hover{
          opacity: .8;
        }

        @include screen-tb {
          width: calc(22.8rem * 0.6);
          height: calc(4.7rem * 0.6);
        }

        @include screen-pc {
          width: 22.8rem;
          height: 4.7rem;
        }
      }
    }
  }

  &__conmpany,
  &__address,
  &__tel,
  &__open,
  &__copy {
    color: $white;
    text-align: center;
  }

  &__conmpany {
    margin-bottom: 2.5rem;
    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
      margin-bottom: calc(3rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
      margin-bottom: 3rem;
    }
  }

  &__address {
    margin-bottom: 2rem;
    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }
  }

  &__tel {
    font-family: $num;
    line-height: 1;
    margin-bottom: 0.5rem;
    font-size: 3.3rem;
    font-weight:$regular;

    @include screen-tb {
      font-size: calc(3.3rem * 0.6);
      font-weight: $semi-bold;
      margin-bottom: calc(1rem * 0.6);
    }

    @include screen-pc {
      font-size: 3.3rem;
      margin-bottom: 1rem;
    }

    span {
      font-size: 1.8rem;
      color: $white;
      margin-right: 1rem;

      @include screen-tb {
        font-size: calc(1.8rem * 0.6);
        margin-right: calc(1rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.8rem;
        margin-right: 1rem;
      }
    }
  }

  &__open {
    margin-bottom: 3rem;
    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
      margin-bottom: calc(4rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
      margin-bottom: 8rem;
    }
  }

  &__copy {
    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
    }
  }
}
