// common

.loader {
  z-index: 1000;
}

.header {
  z-index: 1000;
}

.fix-menu {
  z-index: 100;
}

.fix-buttons {
  z-index: 100;
}

.page {
  &__smoke {
    z-index: 1;
  }
}

// top
.top-slider {
  &__pager {
    z-index: 10;
  }

  &__wrap {
    z-index: 10;
  }

  &__smoke {
    z-index: 10;
  }
}

.top-service {
  &__bg-elm {
    z-index: -1;
  }
  &__wrap {
    z-index: 10;
  }
}

.top-recommend {
  &__bg-elm {
    &:before,
    &:after {
      z-index: -1;
    }
  }
  &__wrap {
    z-index: 10;
  }
}

// service
.sesrviec-sell {
  &__bg-elm {
    &:before,
    &:after {
      z-index: -2;
    }
  }

  &__bg-elm2 {
    z-index: -1;
  }
}

.service-main-img {
  &__right-prof-img {
    &:before {
      z-index: 1;
    }
  }
  &__right {
    &:after {
      z-index: -1;
    }
  }
}

.service-manager {
  &:after {
    z-index: -1;
  }
}
