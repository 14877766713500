// contact-main-img

.contact-main-img {
  @extend .page;
  @include background-image("privacy-bg-sp.jpg");
  height: 33rem;

  @include screen-tb {
    @include background-image("privacy-bg.jpg");
    height: calc(46rem * 0.6);
  }
  @include screen-pc {
    height: 46rem;
  }

  &__wrap {
    padding: 12.5rem 0 0 0;
    @include screen-tb {
      padding: calc(20rem * 0.6) 0 0;
    }

    @include screen-pc {
      padding: 20rem 0 0;
    }
  }
  &__container {
    max-width: 128rem;

    padding: 0 2rem;

    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl-area {
  }

  &__ttl-en {
    letter-spacing: 0;

    span {
      letter-spacing: 0;

      &:nth-child(1) {
        color: $red;
      }
    }
  }

  &__ttl-jp {
    @include screen-tb {
      margin-bottom: calc(2.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 2.5rem;
    }
  }

  &__desc {
    
    line-height: 2;
    font-size: 1.4rem;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
      text-align: center;
    }

    @include screen-pc {
      font-size: 1.6rem;
    }
  }
}

// contact-tel
.contact-tel {
  @extend .page;

  &__wrap {
    padding: 3.5rem 0 4rem;
    @include screen-tb {
      padding: calc(4.5rem * 0.6) 0 calc(7.5rem * 0.6);
    }

    @include screen-pc {
      padding: 4.5rem 0 7.5rem;
    }
  }
  &__container {
    max-width: 128rem;

    @include screen-tb {
      padding: 0 2rem;
    }

    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl,
  &__num,
  &__alert {
    text-align: center;
  }

  &__ttl {
    font-size: 1.6rem;
    @include screen-tb {
      font-size: calc(2.3rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.3rem;
    }

    span {
      border-bottom: 1px solid $black;
    }
  }

  &__num {
    font-family: $num;
    font-size: 4rem;

    @include screen-tb {
      font-size: calc(5.3rem * 0.6);
    }

    @include screen-pc {
      font-size: 5.3rem;
    }

    span {
      font-size: 2rem;
      @include screen-tb {
        font-size: calc(2.7rem * 0.6);
        margin-right: calc(2rem * 0.6);
      }

      @include screen-pc {
        font-size: 2.7rem;
        margin-right: 2rem;
      }
    }
  }

  &__alert {
    font-size: 1.2rem;

    @include screen-tb {
      font-size: calc(1.2rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.2rem;
    }
  }
}

// contact-form

.contact-form {
  &__wrap {
    padding: 5.5rem 0 16rem 0;
    @include screen-tb {
      padding: calc(4.5rem * 0.6) 0 calc(13rem * 0.6);
    }

    @include screen-pc {
      padding: 4.5rem 0 13rem;
    }
  }
  &__container {
    padding: 0 2rem;
    max-width: 64rem;

    @include screen-pc {
      padding: 0;
    }
  }
  &__ttl {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 2.5rem;

    @include screen-tb {
      font-size: calc(2.3rem * 0.6);
      margin-bottom: calc(2.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.3rem;
      margin-bottom: 2.5rem;
    }

    span {
      border-bottom: 1px solid $black;
    }
  }

  &__dl {
    flex-direction: column;
    border-top: 1px solid $gray;

    @include screen-tb {
      flex-direction: row;
      align-items: center;
    }

    &:last-of-type {
      border-bottom: 1px solid $gray;
      align-items: flex-start;
      margin-bottom: 2rem;
    }

    @include screen-tb {
      padding: calc(3rem * 0.6) 0;
     
    }

    @include screen-pc {
      padding: 3rem 0;
      
    }
  }

  &__dt {
    flex-shrink: 0;
    margin: 1.25rem 0;

    @include screen-tb {
      width: calc(20.8rem * 0.6);
      font-size: calc(1.6rem * 0.6);
      margin: 0;
    }

    @include screen-pc {
      width: 20.8rem;
      font-size: 1.6rem;
    }

    span {
      font-size: 1.2rem;
      border-radius: 0.5rem;
      display: inline-block;
      background-color: $blue;
      color: $white;
      padding: 0.2rem 1rem;
      margin-right: 0.5rem;

      @include screen-tb {
        font-size: calc(1.2rem * 0.6);
        border-radius: calc(0.5rem * 0.6);
        padding: calc(0.2rem * 0.6) calc(1rem * 0.6);
        margin-right: calc(0.5rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.2rem;
        border-radius: 0.5rem;
        padding: 0.2rem 1rem;
        margin-right: 0.5rem;
      }
    }
  }

  &__dd {
    margin-bottom: 3rem;

    @include screen-tb {
      width: calc(100% - 20.8rem * 0.6);
      margin: 0;
    }

    @include screen-pc {
      width: calc(100% - 20.8rem);
    }

    // form
    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      height: 40px;
      border: 0;
      padding: 10px;
      font-size: 16px;
      font-family: Arial, sans-serif;
      color: $black;
      border: solid 1px #808080;
      border-radius: 5px;
      background-color: #f1f1f1;
    }
    textarea {
      height: 140px;
    }
  }
  &__privacy-check-box {
    margin-bottom: 2rem;

    @include screen-tb {
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 2rem;
    }
  }

  &__privacy-check {
    line-height: 1;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.6rem;
    }

    input[type="checkbox"] {
      margin-right: 13px;
    }

    span.mwform-checkbox-field {
      margin-bottom: 0.5rem;
      display: inline-block;
    }
  }

  &__privacy-desc {
    margin-bottom: 4.5rem;

    @include screen-tb {
      margin-bottom: calc(7rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 7rem;
    }

    a {
      color: $blue;
      border-bottom: 1px solid $gray;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column-reverse;

    @include screen-tb {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    input[type="submit"] {
      // 初期化
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none;
      border-radius: 0;

      width: 45%;
      height: 60px;
      border: 1px solid $red;
      padding: 15px 30px;
      margin: 0 auto;
      color: $white;
      background-color: $red;
      cursor: pointer;
      display: block;

      &:hover{
        opacity: .8;
      }
    }

    input.contact-confirm {
      // 初期化
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none;
      border-radius: 0;

      color: $white;
      background-color: $red;
      width: 100%;

      &:hover{
        opacity: .8;
      }

      @include screen-tb {
        width: 45%;
      }
    }

    input.contact-back {
      // 初期化
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none;
      border-radius: 0;

      color: $white;
      background-color: $red;
      width: 100%;
      margin-bottom: 1rem;

      &:hover{
        opacity: .8;
      }

      @include screen-tb {
        width: 45%;
        margin-bottom: 0;
      }
    }
  }
}
