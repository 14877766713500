.fix-buttons {
  @extend .page;

  &__list {
    @include fixed($bottom: 0, $left: auto, $right: 0);
    width: auto;
  }

  &__item {
    &:nth-of-type(1) {
      img {
        width: calc(4rem * 0.6);
        height: calc(2.4rem * 0.6);

        @include screen-tb {
          width: calc(4rem * 0.6);
          height: calc(2.4rem * 0.6);
        }

        @include screen-pc {
          width: 4rem;
          height: 2.4rem;
        }
      }
      .fix-buttons__link {
        &:before {
          @include background-image("fix-button1.svg");
          content: "";
          display: block;
          @include absolute($left: calc(-3.1rem * 0.6), $bottom: 0);
          width: calc(3.2rem * 0.6);
          height: calc(8.6rem * 0.6);

          @include screen-tb {
            @include absolute($left: calc(-3.1rem * 0.6), $bottom: 0);
            width: calc(3.2rem * 0.6);
            height: calc(8.6rem * 0.6);
          }

          @include screen-pc {
            @include absolute($left: -3.1rem, $bottom: 0);
            width: 3.2rem;
            height: 8.6rem;
          }
        }

        span.fix-buttons__text{

          &:hover{
            opacity: .5;
          }
        }
      }
    }

    &:nth-of-type(2) {
      img {
        width: calc(3.8rem * 0.6);
        height: calc(2.8rem * 0.6);
        margin-right: .5rem;

        @include screen-tb {
          width: calc(3.8rem * 0.6);
          height: calc(2.8rem * 0.6);
          margin-right: calc(-1rem * 0.6);
        }

        @include screen-pc {
          width: 3.8rem;
          height: 2.8rem;
          margin-right: -1rem;
        }
      }
      .fix-buttons__link {
        
        &:before {
          content: "";
          display: block;
          @include background-image("fix-button2.svg");
          @include absolute($left: calc(-0.1rem * 0.6), $bottom: 0);
          width: calc(3.2rem * 0.6);
          height: calc(8.6rem * 0.6);

          @include screen-tb {
            @include absolute($left: calc(-0.1rem * 0.6), $bottom: 0);
            width: calc(3.2rem * 0.6);
            height: calc(8.6rem * 0.6);
          }

          @include screen-pc {
            @include absolute($left: -0.1rem, $bottom: 0);
            width: 3.2rem;
            height: 8.6rem;
          }
        }

        span.fix-buttons__text{
          letter-spacing: -1px;

          &:hover{
            opacity: .5;
          }
        }
      }
    }
  }

  &__link {
    align-items: center;
    font-size: 1.4rem;
    padding: 0 calc(4rem * 0.5);
    height: calc(8.6rem * 0.6);
    line-height: calc(8.6rem * 0.6);
    letter-spacing: 0;

    @include screen-tb {
      padding: 0 calc(4rem * 0.6);
      font-size: calc(2rem * 0.6);
      height: calc(8.6rem * 0.6);
      line-height: calc(8.6rem * 0.6);
    }

    @include screen-pc {
      padding: 0 4rem;
      font-size: 2rem;
      height: 8.6rem;
      line-height: 8.6rem;
      letter-spacing: .05em;
    }
  }

  &__icon {
    display: block;

    img {
      display: block;
      margin-right: 1rem;
    }

    @include screen-tb {
      margin-right: calc(1rem * .6);
    }

    @include screen-pc {
      margin-right: 1rem;
    }
  }
}
