// main-img
.service-main-img {
  @extend .page;

  &__bg {
    @include absolute($left: 0, $top: 6rem);
    // @include background-image("service-main-sp.jpg");

    width: 100%;
    height: auto;

    @include screen-tb {
      @include absolute($left: 0, $top: 0);
    }

    &:after {
      @include aspect-ratio(824px, 396px);
      @include background-image("service-main-sp.jpg");
      background-size: contain;
      background-position: center bottom 0rem;
      content: "";
      display: block;

      @include screen-tb {
        @include aspect-ratio(3280px, 1206px);
        @include background-image("service-main.jpg");
        background-position: center top calc(8rem * 0.6);
      }

      @include screen-pc {
        background-position: center top 8rem;
      }
    }
  }

  &__wrap {
    padding: 23.5rem 0 4rem;
    overflow: hidden;

    @include screen-tb {
      padding: calc(25.5rem * 0.6) 0 calc(8.5rem * 0.6);
    }

    @include screen-pc {
      padding: 25.5rem 0 8.5rem;
    }
  }
  &__container {
    max-width: 132rem;
    padding: 0 2rem;
  }

  &__ttl-area {
    @include screen-tb {
      margin-bottom: calc(9.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 9.5rem;
    }
  }

  &__ttl-en {
    span.char {
      &:nth-of-type(1) {
        color: $red;
      }
    }
  }

  &__flex {
    flex-direction: column-reverse;

    @include screen-tb {
      flex-direction: row;
    }
  }

  &__left {
    @include screen-tb {
      width: calc(100% - (58rem * 0.6));
      padding: calc(4.5rem * 0.6) calc(3.5rem * 0.6) 0 0;
    }

    @include screen-pc {
      width: calc(100% - 58rem);
      padding: 4.5rem 4.5rem 0 0;
    }
  }

  &__left-ttl {
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 1rem;

    @include screen-tb {
      font-size: calc(2.5rem * 0.6);
      margin-bottom: calc(1.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &__left-sub-ttl {
    font-size: 2.4rem;
    line-height: 1.5;

    font-family: $jp-min;
    font-weight: $bold;

    @include screen-tb {
      font-size: calc(3.2rem * 0.6);
    }

    @include screen-pc {
      font-size: 3.2rem;
    }
  }

  &__left-sub-catch {
    font-family: $jp-min;
    font-weight: $bold;
    color: $blue;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;

    @include screen-tb {
      font-size: calc(2.4rem * 0.6);
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }
  }

  &__left-desc {
    font-size: 1.4rem;
    line-height: 2;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.6rem;
    }
  }

  &__right {
    margin-bottom: 2.5rem;
    position: relative;

    @include screen-tb {
      width: calc(58rem * 0.6);
      margin: 0;
    }

    @include screen-pc {
      width: 58rem;
    }

    &:after {
      @include background-image("service-sp-elm1.png");
      @include absolute($right: -15.5rem, $top: 22rem);
      width: 33.2rem;
      height: 70.2rem;
      content: "";
      display: block;

      @include screen-tb {
        display: none;
      }
    }
  }

  &__right-prof-img {
    border: 3px solid $black;
    position: relative;

    &:after {
      @include aspect-ratio(579px, 501px);
      content: "";
      display: block;
    }

    img {
      @include absolute($top: 0, $left: 0);
      display: block;
      width: 100%;
    }

    &:before {
      @include background-image("owner-name.svg");
      @include absolute($bottom: calc(-4.4rem * 0.6), $right: 0);
      width: calc(30.8rem * 0.6);
      height: calc(17rem * 0.6);
      content: "";
      display: block;

      @include screen-tb {
        @include absolute($bottom: calc(-4.4rem * 0.6), $right: calc(-2rem * 0.6));
        width: calc(30.8rem * 0.6);
        height: calc(17rem * 0.6);
      }

      @include screen-pc {
        @include absolute($bottom: -4.4rem, $right: -2rem);
        width: 30.8rem;
        height: 17rem;
      }
    }
  }

  &__right-prof-name {
    @include absolute($bottom: 2rem, $left: 0);
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    font-family: $jp-min;
    font-weight: $bold;
    font-size: 1.6rem;
    padding: 0.2rem 3rem 0.2rem 2rem;

    @include screen-tb {
      font-size: calc(2.6rem * 0.6);
      padding: calc(0.5rem * 0.6) calc(3.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.6rem;
      padding: 0.5rem 3.5rem;
    }

    span {
      color: $white;
      margin-right: 0.5rem;
      font-size: 1rem;

      @include screen-tb {
        font-size: calc(1.6rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.6rem;
      }
    }
  }
}

.service-manager {
  position: relative;

  &:after {
    @include background-image("service-sp-elm2.png");
    @include absolute($left: 0rem, $top: 19.5rem);
    width: 41.2rem;
    height: 52.8rem;
    content: "";
    display: block;

    @include screen-tb {
      display: none;
    }
  }
  &__wrap {
    padding: 0 0 7.5rem 0;

    @include screen-tb {
      padding: 0 0 calc(4.5rem * 0.6);
    }
    @include screen-pc {
      padding: 0 0 4.5rem;
    }
  }

  &__container {
    max-width: 132rem;
    padding: 0 2rem;
  }

  &__flex {
    flex-direction: column;
    border: 3px solid $black;

    @include screen-tb {
      flex-direction: row;
    }
  }

  &__img {
    @include background-image("store-owner.jpg");
    width: 100%;
    height: auto;
    position: relative;

    @include screen-tb {
      width: calc(43.8rem * 0.6);
    }

    @include screen-pc {
      width: 43.8rem;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &:before {
      @include background-image("store-owner-name.svg");
      @include absolute($bottom: calc(-6.4rem * 0.6), $right: -1.5rem);
      width: calc(22rem * 0.9);
      height: calc(14rem * 0.9);
      content: "";
      display: block;

      @include screen-tb {
        @include absolute($bottom: calc(-4.4rem * 0.6), $right: calc(-1rem * 0.6));
        width: calc(22rem * 0.6);
        height: calc(14rem * 0.6);
      }

      @include screen-pc {
        @include absolute($bottom: -4.4rem, $right: -1rem);
        width: 22rem;
        height: 14rem;
      }
    }
  }

  &__name {
    @include absolute($bottom: 0, $left: 0);
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    font-family: $jp-min;
    font-weight: $bold;
    font-size: 1.6rem;
    padding: 0.5rem 2rem;
    white-space: nowrap;

    @include screen-tb {
      @include absolute($bottom: -3px, $left: 0);
      font-size: calc(2.6rem * 0.6);
      padding: calc(0.5rem * 0.6) calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.6rem;
      padding: 0.5rem 2rem;
    }

    span {
      color: $white;
      margin-right: 1.5rem;
      font-size: 1rem;

      @include screen-tb {
        font-size: calc(1.6rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.6rem;
      }
    }
  }

  &__cont {
    padding: 3rem 2rem;
    background-color: $white;

    @include screen-tb {
      width: calc(100% - 43.8rem * 0.6);
      padding: calc(6.5rem * 0.6);
    }

    @include screen-pc {
      width: calc(100% - 43.8rem);
      padding: 6.5rem;
    }
  }

  &__cont-catch {
    margin-bottom: 0.5rem;
    @include screen-tb {
      margin-bottom: calc(1rem * 0.6);
    }
    @include screen-pc {
      margin-bottom: 1rem;
    }
    span {
      color: $white;
      background-color: $blue;
      font-family: $jp-min;
      font-size: 1.4rem;
      padding: 0.2rem 1rem;
      @include screen-tb {
        font-size: calc(1.8rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.8rem;
      }
    }
  }

  &__cont-ttl {
    font-family: $jp-min;
    font-weight: $bold;
    font-size: 2rem;
    color: $black;
    margin-bottom: 1rem;

    @include screen-tb {
      font-size: calc(2.747rem * 0.6);
      margin-bottom: calc(1.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.747rem;
      margin-bottom: 1.5rem;
    }
  }

  &__cont-desc {
    line-height: 2;
    font-size: 1.4rem;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.6rem;
    }
  }
}

// sesrviec-index
.sesrviec-index {
  @extend .page;
  background-color: rgba($color: #26284c, $alpha: 0.8);
  margin-top: 5.5rem;

  @include screen-tb {
    margin-top: calc(11.5rem * 0.6);
  }

  @include screen-pc {
    margin-top: 11.5rem;
  }

  &:after {
    @include background-image("smoke_SP.png");
    @include absolute($top: -86%, $left: 0);
    @include aspect-ratio(824px, 340px);
    content: "";
    display: block;
    width: 100%;
    height: auto;
    display: block;
    background-size: contain;
    background-position: bottom center;

    @include screen-tb {
      display: none;
    }
  }

  &__wrap {
    padding: 2rem 0;
    @include screen-tb {
      padding: calc(2.5rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 2.5rem 0;
    }
  }
  &__container {
    max-width: 96rem;
    padding: 0 2rem;

    @include screen-pc {
      padding: 0;
    }
  }

  &__list {
    justify-content: space-between;
    border: 1px solid $blue;
    flex-wrap: wrap;
  }

  &__item {
    width: 50%;

    @include screen-tb {
      width: 20%;
    }

    &:not(last-of-type) {
      border-right: 1px solid $blue;
      border-bottom: 1px solid $blue;

      @include screen-tb {
        border-bottom: 0;
      }
    }

    &:nth-of-type(3) {
      width: 100%;
      @include screen-tb {
        width: 20%;
      }
    }
  }

  &__link {
    height: 5rem;
    align-items: center;
    background-color: $white;
    position: relative;
    line-height: 1.2;
    padding: 0 2rem;
    font-size: 1.4rem;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      justify-content: center;
      height: calc(7.7rem * 0.6 - 2px);
      font-size: calc(1.6rem * 0.6);
      padding: 0;
    }

    @include screen-pc {
      height: calc(7.7rem - 2px);
      font-size: 1.6rem;
    }

    span {
      @include screen-tb {
        margin-top: calc(-1rem * 0.6);
      }
      @include screen-pc {
        margin-top: -1rem;
      }
    }

    &:after {
      @include background-image("arrow-service.svg");
      position: absolute;
      left: auto;
      right: 1rem;
      transform: translateY(-50%);
      top: 50%;
      content: "";
      display: block;
      width: 1.6rem;
      height: 0.8rem;

      @include screen-tb {
        width: calc(1.6rem * 0.6);
        height: calc(0.8rem * 0.6);
        left: 50%;
        top: auto;
        right: auto;
        transform: translateY(0) translateX(-50%);
        bottom: 0.5rem;
      }

      @include screen-pc {
        width: 1.6rem;
        height: 0.8rem;
      }
    }
  }
}

// sesrviec-sell
.sesrviec-sell {
  @extend .page;

  &__smoke {
    @include background-image("smoke_SP.png");
    @include absolute($top: 0, $left: 0);
    width: 100%;
    height: 100%;
    display: block;
    background-size: contain;
    background-position: bottom center;

    @include screen-tb {
      @include background-image("smoke_PC.png");
      background-size: contain;
      background-position: bottom center;
    }
  }

  &__bg-elm {
    display: none;

    @include screen-tb {
      @include absolute($top: 0, $left: 0);
      display: block;
      width: 100%;
      height: 100%;
    }

    &:before,
    &:after {
      @include absolute($top: 0, $left: 0);
      width: 50%;
      height: 100%;
      content: "";
      display: block;
    }

    &:before {
      background-color: rgba($color: #202387, $alpha: 0.15);

      @include screen-tb {
        @include absolute($top: calc(-45rem * 0.6), $left: 0);
        height: calc(100% + (45rem * 0.6));
      }

      @include screen-pc {
        @include absolute($top: -45rem, $left: 0);
        height: calc(100% + 45rem);
      }
    }

    &:after {
      @include absolute($top: -19.5rem, $left: 50%);
      background-color: rgba($color: #000000, $alpha: 0.15);

      @include screen-tb {
        @include absolute($top: calc(-88rem * 0.6), $left: 50%);
        height: calc(100% + 88rem * 0.6);
      }

      @include screen-pc {
        @include absolute($top: -88rem, $left: 50%);
        height: calc(100% + 88rem);
      }
    }
  }

  &__bg-elm2 {
    display: none;

    @include screen-tb {
      @include absolute($top: calc(-88rem * 0.6), $left: 50%);
      background-color: $white;
      width: 62%;
      height: calc(100% + calc(88rem * 0.6));
      transform: translateX(-58%) skewX(20deg);
      display: block;
    }

    @include screen-pc {
      @include absolute($top: -88rem, $left: 50%);
      height: calc(100% + 88rem);
    }
  }

  &__wrap {
    padding: 5rem 0 6rem;

    @include screen-tb {
      padding: calc(7rem * 0.6) 0 calc(6rem * 0.6);
    }

    @include screen-pc {
      padding: 7rem 0 6rem;
    }
  }

  &__container {
    padding: 0 2rem;
    max-width: 96rem;

    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl-en {
    text-align: center;
    font-size: 4rem;

    @include screen-tb {
      font-size: calc(5.2rem * 0.6);
    }

    @include screen-pc {
      font-size: 5.2rem;
    }
  }

  &__ttl-jp {
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 1rem;

    @include screen-tb {
      font-size: calc(2.2rem * 0.6);
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.2rem;
      margin-bottom: 2rem;
    }
  }

  &__banner {
    @include background-image("sample-service-sell-sp.png");
    width: 100%;
    height: 16.5rem;
    display: block;
    position: relative;
    border: 5px solid #333333;
    background-color: #fff;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      @include background-image("sample-service-sell.png");
      width: 100%;
      height: calc(18.5rem * 0.6);
    }

    @include screen-pc {
      height: 18.5rem;
    }
  }

  &__banner-text {
    @include absolute($top: auto, $bottom: 2rem, $left: 2rem);
    font-size: 2.2rem;

    @include screen-tb {
      @include absolute($top: 50%, $bottom: auto, $left: calc(5.5rem * 0.6));
      transform: translateY(-50%);
      font-size: calc(3.1rem * 0.6);
    }

    @include screen-pc {
      @include absolute($top: 50%, $left: 5.5rem);
      font-size: 3.1rem;
    }

    &:before {
      @include background-image("car-sensor-logo.svg");
      content: "";
      display: inline-block;
      width: 6rem;
      height: 1.7rem;
      margin-right: 1rem;

      @include screen-tb {
        width: calc(9rem * 0.6);
        height: calc(2.5rem * 0.6);
        display: block;
        margin: 0;
      }

      @include screen-pc {
        width: 9rem;
        height: 2.5rem;
      }
    }
  }

  &__banner-arrow {
    @include absolute($top: auto, $bottom: 2rem, $right: 2rem);
    width: 2.4rem;
    height: 2.4rem;

    @include screen-tb {
      @include absolute($top: 50%, $bottom: auto, $right: calc(3rem * 0.6));
      transform: translateY(-50%);
      width: calc(3.2rem * 0.6);
      height: calc(3.2rem * 0.6);
    }

    @include screen-pc {
      @include absolute($top: 50%, $right: 3rem);
      transform: translateY(-50%);
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}

// sesrviec-safety-check
.sesrviec-safety-check {
  @extend .page;
  background-color: $white;

  &__wrap {
    @include screen-tb {
      padding: calc(5.05rem * 0.6) 0 calc(3rem * 0.6);
    }

    @include screen-pc {
      padding: 5.05rem 0 3rem;
    }
  }

  &__symbol {
    width: 100%;
    height: 2px;
    background-color: $blue;
    position: relative;

    &:after {
      @include background-image("service-img1.svg");
      @include center(xy);
      width: 7.1rem;
      height: 7.1rem;
      content: "";
      display: block;

      @include screen-tb {
        width: calc(10.1rem * 0.6);
        height: calc(10.1rem * 0.6);
      }

      @include screen-pc {
        width: 10.1rem;
        height: 10.1rem;
      }
    }
  }

  &__container {
    max-width: 96rem;

    @include screen-tb {
      padding: 0 2rem;
    }

    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl {
    text-align: center;
    font-size: 2rem;
    margin: 6rem 0 2rem;

    @include screen-tb {
      font-size: calc(2.8rem * 0.6);
      margin: calc(8rem * 0.6) 0 calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.8rem;
      margin: 8rem 0 2rem;
    }
  }

  &__list {
    margin-bottom: 4rem;
    @include screen-tb {
      margin-bottom: calc(3rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 3rem;
    }
  }

  &__item {
    margin-bottom: 1rem;

    &:not(:last-of-type) {
      @include screen-tb {
        margin-bottom: calc(1rem * 0.6);
      }

      @include screen-pc {
        margin-bottom: 1rem;
      }
    }
  }

  &__item-ttl {
    align-items: center;
    cursor: pointer;
    height: 6rem;
    font-size: 1.6rem;
    background-color: #f1f1f1;
    padding: 0 2rem;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      width: 100%;
      height: calc(8rem * 0.6);

      font-size: calc(1.8rem * 0.6);
      padding: 0 calc(3rem * 0.6);
    }

    @include screen-pc {
      height: 8rem;
      font-size: 1.8rem;
      padding: 0 3rem;
    }

    &.is-active {
      &:after {
        transform: scale(1, 1);
      }
    }

    span {
      font-size: 1.2rem;

      @include screen-tb {
        font-size: calc(1.4rem * 0.6);
        color: #666666;
        margin-left: calc(1rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.4rem;
        margin-left: 1rem;
      }
    }

    &:after {
      @include background-image("arrow-service-check.svg");
      width: 2rem;
      height: 1.2rem;
      content: "";
      display: block;
      margin-left: auto;
      transform: scale(1, -1);
      transition: transform 0.3s $gnav-easing;

      @include screen-tb {
        width: calc(2rem * 0.6);
        height: calc(1.2rem * 0.6);
      }

      @include screen-pc {
        width: 2rem;
        height: 1.2rem;
      }
    }
  }

  &__item-wrap {
    display: none;
    opacity: 0;

    @include screen-tb {
      margin-bottom: calc(5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 5rem;
    }

    &.is-open {
      display: block;
      opacity: 1;
      animation: fade-in 0.3s $gnav-easing 0s;
    }
  }

  &__item-flex {
    flex-direction: column;
    margin-bottom: 2rem;
    @include screen-tb {
      margin-bottom: calc(3.5rem * 0.6);
      justify-content: space-between;
      flex-direction: row;
    }

    @include screen-pc {
      margin-bottom: 3.5rem;
    }
  }

  &__item-left,
  &__item-right {
    width: 100%;

    @include screen-tb {
      width: calc(50% - calc(1rem * 0.6));
    }

    @include screen-pc {
      width: calc(50% - 1rem);
    }
  }

  &__item-dl {
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid $gray;

    @include screen-tb {
      padding: calc(1.2rem * 0.6) calc(1rem * 0.6);

      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      padding: 1.2rem 1rem;
      font-size: 1.6rem;
    }
  }

  &__item-total {
    font-size: 1.4rem;
    width: calc(100% - 4rem);
    height: 5rem;
    align-items: center;
    justify-content: center;
    border: 1px solid $blue;
    margin: 0 auto 3rem;

    @include screen-tb {
      width: 100%;
      height: calc(6rem * 0.6);
      font-size: calc(1.6rem * 0.6);
      margin: 0;
    }

    @include screen-pc {
      height: 6rem;
      font-size: 1.6rem;
    }
  }

  &__allert-ttl,
  &__allert-desc,
  &__discount-ttl,
  &__discount-desc {
    font-size: 1.4rem;

    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
    }
  }

  &__allert-ttl,
  &__discount-ttl {
    padding: 0 2rem;
    margin-bottom: 1rem;
    @include screen-tb {
      margin-bottom: calc(1rem * 0.6);
      padding: 0;
    }

    @include screen-pc {
      margin-bottom: 1rem;
    }
  }

  &__allert-desc,
  &__discount-desc {
    line-height: 2;
    margin-bottom: 4rem;
    padding: 0 2rem;

    @include screen-tb {
      margin-bottom: calc(6.5rem * 0.6);
      padding: 0;
    }

    @include screen-pc {
      margin-bottom: 6.5rem;
    }
  }
}

// sesrviec-safety-buy
.sesrviec-safety-buy {
  @extend .page;
  padding: 0 0 4rem;

  &__wrap {
    @include screen-tb {
      padding: calc(5.05rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 5.05rem 0;
    }
  }

  &__symbol {
    width: 100%;
    height: 2px;
    background-color: $blue;
    position: relative;

    &:after {
      @include background-image("service-img2.svg");
      @include center(xy);
      width: 7.1rem;
      height: 7.1rem;
      content: "";
      display: block;

      @include screen-tb {
        width: calc(10.1rem * 0.6);
        height: calc(10.1rem * 0.6);
      }

      @include screen-pc {
        width: 10.1rem;
        height: 10.1rem;
      }
    }
  }

  &__container {
    max-width: 96rem;

    @include screen-tb {
      padding: 0 2rem;
    }

    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl {
    text-align: center;
    font-size: 2rem;
    margin: 6rem 0 2rem;

    @include screen-tb {
      font-size: calc(2.8rem * 0.6);
      margin: calc(8rem * 0.6) 0 calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.8rem;
      margin: 8rem 0 2rem;
    }
  }

  &__allert {
  }

  &__allert-desc {
    p {
      font-size: 1.4rem;
      line-height: 2;
      margin-bottom: 4rem;
      padding: 0 2rem;

      @include screen-tb {
        font-size: calc(1.4rem * 0.6);
        margin-bottom: 3.5rem;
        padding: 0;
      }

      @include screen-pc {
        font-size: 1.4rem;
      }

      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}

// sesrviec-safety-maintenance
.sesrviec-safety-maintenance {
  @extend .page;

  &__wrap {
    @include screen-tb {
      padding: calc(5.05rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 5.05rem 0;
    }
  }

  &__symbol {
    width: 100%;
    height: 2px;
    background-color: $blue;
    position: relative;

    &:after {
      @include background-image("service-img3.svg");
      @include center(xy);
      width: 7.1rem;
      height: 7.1rem;
      content: "";
      display: block;

      @include screen-tb {
        width: calc(10.1rem * 0.6);
        height: calc(10.1rem * 0.6);
      }

      @include screen-pc {
        width: 10.1rem;
        height: 10.1rem;
      }
    }
  }

  &__container {
    max-width: 96rem;

    @include screen-tb {
      padding: 0 2rem;
    }

    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl {
    text-align: center;
    font-size: 2rem;
    margin: 6rem 0 2rem;

    @include screen-tb {
      font-size: calc(2.8rem * 0.6);
      margin: calc(8rem * 0.6) 0 calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.8rem;
      margin: 8rem 0 2rem;
    }
  }

  &__allert {
  }

  &__allert-desc {
    font-size: 1.4rem;
    line-height: 2;
    margin-bottom: 4rem;
    padding: 0 2rem;

    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
      margin-bottom: 3.5rem;
      padding: 0;
    }

    @include screen-pc {
      font-size: 1.4rem;
    }

    p {
      font-size: 1.4rem;
      line-height: 2;
      margin-bottom: 4rem;
      padding: 0 2rem;

      @include screen-tb {
        font-size: calc(1.4rem * 0.6);
        margin-bottom: 3.5rem;
        padding: 0;
      }

      @include screen-pc {
        font-size: 1.4rem;
      }

      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}

// sesrviec-cocst
.sesrviec-cocst {
  @extend .page;
  border-top: 1px solid $black;

  &__wrap {
    @include screen-tb {
      padding: calc(5.05rem * 0.6) 0 calc(14rem * 0.6);
    }

    @include screen-pc {
      padding: 5.05rem 0 14rem;
    }
  }

  &__symbol {
    width: 100%;
    height: 1px;
    background-color: $black;
    position: relative;
  }

  &__container {
    max-width: 96rem;

    @include screen-tb {
      padding: 0 2rem;
    }

    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl {
    text-align: center;
    font-size: 2rem;
    padding: 6rem 0 2rem;

    @include screen-tb {
      font-size: calc(2.8rem * 0.6);
      padding: calc(8rem * 0.6) 0 calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.8rem;
      padding: 8rem 0 2rem;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
      @include screen-tb {
        margin-bottom: calc(1rem * 0.6);
      }

      @include screen-pc {
        margin-bottom: 1rem;
      }
    }
  }

  &__item-ttl {
    align-items: center;
    cursor: pointer;
    height: 6rem;
    font-size: 1.6rem;
    background-color: #f1f1f1;
    padding: 0 2rem;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      width: 100%;
      height: calc(8rem * 0.6);

      font-size: calc(1.8rem * 0.6);
      padding: 0 calc(3rem * 0.6);
      margin-bottom: calc(0.5rem * 0.6);
    }

    @include screen-pc {
      height: 8rem;
      font-size: 1.8rem;
      padding: 0 3rem;
      margin-bottom: 0.5rem;
    }

    &.is-active {
      &:after {
        transform: scale(1, 1);
      }
    }

    span {
      font-size: 1.2rem;

      @include screen-tb {
        font-size: calc(1.4rem * 0.6);
        color: #666666;
        margin-left: calc(1rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.4rem;
        margin-left: 1rem;
      }
    }

    &:after {
      @include background-image("arrow-service-check.svg");
      width: 2rem;
      height: 1.2rem;
      content: "";
      display: block;
      margin-left: auto;
      transform: scale(1, -1);
      transition: transform 0.3s $gnav-easing;

      @include screen-tb {
        width: calc(2rem * 0.6);
        height: calc(1.2rem * 0.6);
      }

      @include screen-pc {
        width: 2rem;
        height: 1.2rem;
      }
    }
  }

  &__item-wrap {
    display: none;
    opacity: 0;

    @include screen-tb {
      margin-bottom: calc(6rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 6rem;
    }

    &.is-open {
      display: block;
      opacity: 1;
      animation: fade-in 0.3s $gnav-easing 0s;
    }
  }

  &__item-flex {
    flex-direction: column;
    margin-bottom: 2rem;
    @include screen-tb {
      margin-bottom: calc(3rem * 0.6);
      justify-content: space-between;
      flex-direction: row;
    }

    @include screen-pc {
      margin-bottom: 3rem;
    }
  }

  &__item-left,
  &__item-right {
    width: 100%;

    @include screen-tb {
      width: calc(50% - calc(1rem * 0.6));
    }

    @include screen-pc {
      width: calc(50% - 1rem);
    }
  }

  &__item-dl {
    // justify-content: space-between;
    align-items: flex-start;
    font-size: 1.4rem;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid $gray;

    @include screen-tb {
      padding: calc(1.2rem * 0.6) calc(1rem * 0.6);

      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      padding: 1.2rem 1rem;
      font-size: 1.6rem;
    }
  }

  &__item-dt {
    flex-shrink: 0;
    padding-right: 2rem;

    @include screen-tb {
      width: 11.5rem;
      padding-right: calc(2rem * 0.6);
    }

    @include screen-pc {
      width: 18.2rem;
      padding-right: 2rem;
    }
  }

  &__item-dd {
    margin-left: auto;
    a {
      border-bottom: 1px solid $black;
    }
  }

  &__allert-desc,
  &__discount-desc {
    line-height: 2;
    margin: 2rem 0 4rem;
    padding: 0 2rem;

    @include screen-tb {
      margin: 3rem 0 calc(6.5rem * 0.6);
      padding: 0;
    }

    @include screen-pc {
      margin: 4rem 0 6.5rem;
    }
  }
}

// sesrviec-gurantee
.sesrviec-gurantee {
  @extend .page;

  &__wrap {
    @include screen-tb {
      padding: calc(5.05rem * 0.6) 0 calc(14rem * 0.6);
    }

    @include screen-pc {
      padding: 5.05rem 0 14rem;
    }
  }

  &__symbol {
    width: 100%;
    height: 1px;
    background-color: $black;
    position: relative;
  }

  &__container {
    max-width: 96rem;

    @include screen-tb {
      padding: 0 2rem;
    }

    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl {
    text-align: center;
    font-size: 2rem;
    padding: 6rem 0 2rem;

    @include screen-tb {
      font-size: calc(2.8rem * 0.6);
      padding: calc(9rem * 0.6) 0 calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.8rem;
      padding: 9rem 0 2rem;
    }
  }

  &__list {
    margin-bottom: 4rem;
    @include screen-tb {
      margin-bottom: calc(3rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 3rem;
    }
  }

  &__item {
    margin-bottom: 1rem;

    &:not(:last-of-type) {
      @include screen-tb {
        margin-bottom: calc(1rem * 0.6);
      }

      @include screen-pc {
        margin-bottom: 1rem;
      }
    }
  }

  &__item-ttl {
    align-items: center;
    cursor: pointer;
    height: 6rem;
    font-size: 1.6rem;
    background-color: #f1f1f1;
    padding: 0 2rem;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      width: 100%;
      height: calc(8rem * 0.6);

      font-size: calc(1.8rem * 0.6);
      padding: 0 calc(3rem * 0.6);
      margin-bottom: calc(0.5rem * 0.6);
    }

    @include screen-pc {
      height: 8rem;
      font-size: 1.8rem;
      padding: 0 3rem;
      margin-bottom: 0.5rem;
    }

    &.is-active {
      &:after {
        transform: scale(1, 1);
      }
    }

    span {
      font-size: 1.2rem;

      @include screen-tb {
        font-size: calc(1.4rem * 0.6);
        color: #666666;
        margin-left: calc(1rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.4rem;
        margin-left: 1rem;
      }
    }

    &:after {
      @include background-image("arrow-service-check.svg");
      width: 2rem;
      height: 1.2rem;
      content: "";
      display: block;
      margin-left: auto;
      transform: scale(1, -1);
      transition: transform 0.3s $gnav-easing;

      @include screen-tb {
        width: calc(2rem * 0.6);
        height: calc(1.2rem * 0.6);
      }

      @include screen-pc {
        width: 2rem;
        height: 1.2rem;
      }
    }
  }

  &__item-wrap {
    display: none;
    opacity: 0;

    @include screen-tb {
      margin-bottom: calc(5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 5rem;
    }

    &.is-open {
      display: block;
      opacity: 1;
      animation: fade-in 0.3s $gnav-easing 0s;
    }
  }

  &__item-flex {
    flex-direction: column;
    margin-bottom: 2rem;
    @include screen-tb {
      margin-bottom: calc(3rem * 0.6);
      justify-content: space-between;
      flex-direction: row;
    }

    @include screen-pc {
      margin-bottom: 3rem;
    }
  }

  &__item-left,
  &__item-right {
    width: 100%;

    @include screen-tb {
      width: calc(50% - calc(1rem * 0.6));
    }

    @include screen-pc {
      width: calc(50% - 1rem);
    }
  }

  &__item-dl {
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid $gray;

    @include screen-tb {
      padding: calc(1.2rem * 0.6) calc(1rem * 0.6);

      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      padding: 1.2rem 1rem;
      font-size: 1.6rem;
    }
  }

  &__allert-desc,
  &__discount-desc {
    line-height: 2;
    margin: 2rem 0 4rem;
    padding: 0 2rem;
    font-size: 1.4rem;

    @include screen-tb {
      margin: calc(1rem * 0.6) 0;
      padding: 0;
      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      margin: 1rem 0;
      font-size: 1.6rem;
    }
  }

  &__sub-list {
    list-style-type: decimal;
    padding: 0 2rem 0 4rem;
    font-size: 1.4rem;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
      padding: 0 0 0 calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.6rem;
      padding: 0 0 0 2rem;
    }
  }

  &__sub-item {
    &:not(last-of-type) {
      margin-bottom: 0.5rem;

      @include screen-tb {
        margin-bottom: calc(0.5rem * 0.6);
      }

      @include screen-pc {
        margin-bottom: 0.5rem;
      }
    }
  }
}
