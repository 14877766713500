// Common

.top-main {
  overflow: hidden;
}

.js-text {
  overflow: hidden;

  span.char {
    display: inline-block;
  }

  &.inview {
    span.char {
      @include animation(
        $name: animate-chars,
        $duration: 0.5s,
        $timing-function: cubic-bezier(0.39, 1.57, 0.58, 1),
        $fill-mode: both
      );

      @for $i from 1 through 99 {
        &:nth-child(#{$i}) {
          animation-delay: $i * 0.06s;
        }
      }
    }
  }
}

.js-scroll {
  span.char {
    display: inline-block;
  }

  &.inview {
    span.char {
      @include animation(
        $name: animate-chars,
        $duration: 0.5s,
        $timing-function: cubic-bezier(0.39, 1.57, 0.58, 1),
        $fill-mode: both
      );

      @for $i from 1 through 99 {
        &:nth-child(#{$i}) {
          animation-delay: $i * 0.06s;
        }
      }
    }
  }
}

// .js-text.inview {

//   span.char {
//     transition-delay: 0.06s;
//   }
// }

// slider
.top-slider {
  @extend .page;
  height: 41.2rem;
  overflow: visible !important;

  @include screen-tb {
    height: calc(86.3rem * 0.6);
  }

  @include screen-pc {
    height: 86.3rem;
  }

  &__list {
    @include absolute($top: 0, $left: 0);
    width: 100%;
    height: auto;

    @include screen-tb {
      height: calc(86.3rem * 0.6);
    }

    @include screen-pc {
      height: 86.3rem;
    }
  }
  &__item {
    @include absolute($top: 0, $left: 0);
    width: 100%;
    will-change: opacity, transform;
    overflow: hidden;

    @include screen-tb {
      height: calc(86.3rem * 0.6);
    }

    @include screen-pc {
      height: 86.3rem;
    }

    // &:nth-of-type(1){
    //   @include background-image("service-bg.jpg");
    // }

    &--1 {
      .top-slider__img {
        @include background-image("slide1-sp.jpg");

        @include screen-tb {
          @include background-image("slide1.jpg");
        }
      }
    }
    &--2 {
      .top-slider__img {
        @include background-image("slide2-sp.jpg");

        @include screen-tb {
          @include background-image("slide2.jpg");
        }
      }
    }
    &--3 {
      .top-slider__img {
        @include background-image("slide3-sp.jpg");

        @include screen-tb {
          @include background-image("slide3.jpg");
        }
      }
    }
    &--4 {
      .top-slider__img {
        @include background-image("slide4-sp.jpg");

        @include screen-tb {
          @include background-image("slide4.jpg");
        }
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    will-change: transform;

    .swiper-slide-active & {
      animation: zoomUp 4.5s linear 0s 1 normal both;
    }

    .swiper-slide-duplicate-active & {
      animation: zoomUp 4.5s linear 0s 1 normal both;
    }

    .swiper-slide-prev & {
      animation: zoomUp 4.5s linear 0s 1 normal both;
    }
  }

  &__smoke {
    @include background-image("smoke_SP.png");
    @include absolute($top: 0, $left: 0);
    width: 100%;
    height: 100%;
    display: block;
    background-size: contain;
    background-position: bottom center;

    @include screen-tb {
      @include background-image("smoke_PC.png");
      background-size: contain;
      background-position: bottom center;
    }
  }

  &__pager {
    @include absolute($bottom: -10rem, $left: auto, $right: 1rem);
    width: 2.7rem;
    height: auto;

    @include screen-tb {
      @include absolute($bottom: 3rem, $left: 0);
      width: 100%;
      height: calc(3.8rem * 0.6);
      padding: 0 calc(3.5rem * 0.6);
    }

    @include screen-pc {
      height: 3.8rem;
      padding: 0 3.5rem;
    }

    span.swiper-pagination-bullet {
      width: 2.7rem;
      height: 3.8rem;
      display: block;
      position: relative;
      margin-bottom: 0.5rem;

      @include screen-tb {
        display: inline-block;
        width: calc(13.5rem * 0.6);
        height: calc(3.8rem * 0.6);
        border-radius: 0;
        margin-right: calc(1rem * 0.6);
        margin-bottom: 0;
      }

      @include screen-pc {
        width: 13.5rem;
        height: 3.8rem;
        margin-right: 1rem;
      }

      &:before {
        width: 100%;
        height: 100%;
        border: 1px solid $black;
        content: "";
        display: block;

        @include screen-tb {
          transform: skewX(-20deg);
        }
      }

      @for $i from 1 through 10 {
        .foo-#{$i} {
          width: 10px * $i;
        }

        &:nth-of-type(#{$i}) {
          &:after {
            @include center(xy);
            content: "0#{$i}";
            display: block;
            font-family: $jp-min;
            font-size: 1.2rem;

            @include screen-tb {
              font-size: calc(1.6rem * 0.6);
            }

            @include screen-pc {
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    span.swiper-pagination-bullet-active {
      &:before {
        background-color: $black;
      }
      &:after {
        color: $white;
      }
    }
  }

  &__wrap {
    @include absolute($top: 0, $left: 0);
    padding: 31.5rem 0 3rem;

    @include screen-tb {
      padding: calc(22.5rem * 0.6) 0 calc(3rem * 0.6);
    }

    @include screen-pc {
      padding: 22.5rem 0 3rem;
    }
  }

  &__container {
    padding: 2rem;

    @include screen-tb {
      padding: 0 0rem 0 calc(11rem * 0.6);
    }

    @include screen-pc {
      padding: 0 0rem 0 11rem;
    }
  }

  &__en {
    line-height: 0.91;
    position: relative;
    font-size: 4.759rem;
    margin-bottom: 0.5rem;

    @include screen-tb {
      font-size: calc(11.2rem * 0.6);
      font-size: calc(112px * 0.6);
      margin-bottom: 0;
    }

    @include screen-pc {
      font-size: 11.2rem;
      font-size: 112px;
    }

    span.char {
      color: $black;
      letter-spacing: 0.045em;

      &:nth-child(5),
      &:nth-child(7),
      &:nth-child(16) {
        display: none;
      }

      @include screen-tb {
        color: $white;
      }
    }

    &:nth-of-type(1) {
      letter-spacing: 0.075em;
    }

    &:nth-of-type(2) {
      letter-spacing: 0.02em;
    }
  }

  &__jp {
    color: $black;
    font-size: 1.3rem;
    letter-spacing: -0.1em;
    margin-bottom: 1.8rem;

    // inview
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: all 0.5s $gnav-easing;
    will-change: opacity, transform;

    .inview & {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }

    @include screen-tb {
      color: $white;
      font-size: calc(3rem * 0.6);
      font-size: calc(30px * 0.6);
      margin: calc(2rem * 0.6) 0 calc(6rem * 0.6);
    }

    @include screen-pc {
      font-size: 3rem;
      font-size: 30px;
      margin: 2rem 0 6rem;
    }

    span {
      color: $white;
      padding: 0 0.5rem;
    }
  }

  &__logo {
    // inview
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: all 0.5s $gnav-easing 0.25s;
    will-change: opacity, transform;

    .inview & {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }

    @include screen-tb {
      margin: 0 calc(2rem * 0.6) 0 calc(0.3rem * 0.6);
    }

    @include screen-pc {
      margin: 0 2rem 0 0.3rem;
    }
    picture {
      img {
        display: block;
        width: 28rem;
        height: 2.4rem;

        @include screen-tb {
          width: calc(43.8rem * 0.6);
          height: calc(3.5rem * 0.6);
        }

        @include screen-pc {
          width: 43.8rem;
          height: 3.5rem;
        }
      }
    }
  }
}

//style
.top-style {
  @extend .page;

  &__wrap {
    padding: 15rem 0 0;

    @include screen-tb {
      padding: calc(2.5rem * 0.6) 0 calc(18.5rem * 0.6);
    }

    @include screen-pc {
      padding: 2.5rem 0 18.5rem;
    }
  }

  &__container {
  }

  &__page-flex {
    flex-direction: column;

    @include screen-tb {
      flex-direction: row;
    }
  }

  &__img {
    height: auto;
    display: block;
    width: 36.4rem;
    height: 20.4rem;

    @include screen-tb {
      width: calc(86rem * 0.6);
      max-width: 53%;
    }

    @include screen-pc {
      width: 86rem;
    }

    picture {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      @include aspect-ratio(728px, 408px);

      @include screen-tb {
        @include aspect-ratio(860px, 633px);
      }

      img {
        @include absolute($top: 0, $left: 0);
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__cont {
    padding: 0 2rem;
    margin: -1rem 0 0 0;

    @include screen-tb {
      width: calc((100% - 86rem) * 0.6);
      min-width: 47%;
      flex-shrink: 0;
      padding: calc(6rem * 0.6) 0 0 calc(3rem * 0.6);
      margin-top: 0;
    }

    @include screen-pc {
      width: calc(100% - 86rem);
      padding: 6rem 0 0 3rem;
    }
  }

  &__ttl-en {
    font-size: 2.34rem;
    margin-bottom: 1rem;

    @include screen-tb {
      font-size: calc(3.8rem * 0.6);
      margin-bottom: calc(2.2rem * 0.6);
    }

    @include screen-pc {
      font-size: 3.8rem;
      margin-bottom: 2.2rem;
    }

    span {
      color: $blue;
    }
  }

  &__jp {
    line-height: 1.45;
    font-size: 2.4rem;
    letter-spacing: -0.07em;

    @include screen-tb {
      font-size: calc(4rem * 0.6);
      margin-bottom: calc(1rem * 0.6);
      letter-spacing: -0.075em;
    }

    @include screen-pc {
      font-size: 4rem;
      margin-bottom: 1rem;
    }
  }

  &__desc {
    line-height: 2;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
      margin-bottom: calc(5.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.6rem;
      margin-bottom: 5.5rem;
    }
  }

  &__button {
    width: 18.9rem;
    height: 4.4rem;
    line-height: 4.4rem;
    display: block;
    padding: 0 2rem;
    font-size: 1.4rem;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      width: calc(31rem * 0.6);
      height: calc(7.2rem * 0.6);
      line-height: calc(7.2rem * 0.6);
      padding: 0 calc(3rem * 0.6);
      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      width: 31rem;
      height: 7.2rem;
      line-height: 7.2rem;
      padding: 0 3rem;
      font-size: 1.6rem;
    }
  }
}

// service
.top-service {
  @extend .page;

  &__smoke {
    @include background-image("smoke_SP.png");
    @include absolute($top: 0, $left: 0);
    width: 100%;
    height: 100%;
    display: block;
    background-size: contain;
    background-position: bottom center;

    @include screen-tb {
      @include background-image("smoke_PC.png");
      background-size: contain;
      background-position: bottom center;
    }
  }

  &__bg-elm {
    @include absolute($top: -2rem, $left: 0);
    @include background-image("service-bg.jpg");
    width: 100%;
    height: calc(100% + 2rem);

    @include screen-tb {
      @include absolute($top: calc(-22rem * 0.6), $left: 0);
      width: 100%;
      height: calc(100% + (22rem * 0.6));
    }

    @include screen-pc {
      @include absolute($top: -22rem, $left: 0);
      height: calc(100% + 22rem);
    }

    &:after {
      @include absolute($top: 0rem, $left: auto, $right: -44rem);
      height: 100%;
      content: "";
      display: block;
      background-color: $white;
      width: 42rem;
      transform: skewX(-20deg);

      @include screen-tb {
        @include absolute($top: 0rem, $left: auto, $right: calc(-19rem * 0.6));
        width: calc(42rem * 0.6);
      }

      @include screen-pc {
        @include absolute($top: 0rem, $left: auto, $right: -19rem);
        width: 42rem;
      }
    }
  }

  &__wrap {
    position: relative;
    padding: 5rem 0 4.55rem;

    @include screen-tb {
      padding: 0 0 calc(8.5rem * 0.6);
    }

    @include screen-pc {
      padding: 0 0 8.5rem;
    }
  }

  &__container {
    max-width: calc(146rem * 0.6);
    padding: 0 2rem;

    @include screen-tb {
      max-width: calc(146rem * 0.6);
    }

    @include screen-pc {
      max-width: 150rem;
    }
  }

  &__ttl-en {
    text-align: center;
    font-size: 4.8rem;

    @include screen-tb {
      font-size: calc(7rem * 0.6);
    }

    @include screen-pc {
      font-size: 7rem;
    }

    span.char {
      @include screen-tb {
        letter-spacing: calc(3px * 0.6);
      }

      @include screen-pc {
        letter-spacing: 3px;
      }
      &:nth-child(1) {
        color: $red;
      }
    }
  }

  &__ttl-jp {
    text-align: center;
    margin-bottom: 1rem;

    // inview
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: all 0.5s $gnav-easing;
    will-change: opacity, transform;

    .inview & {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }

    @include screen-tb {
      font-size: calc(2.2rem * 0.6);
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.2rem;
      margin-bottom: 2rem;
    }
  }

  &__flex {
    flex-direction: column;

    @include screen-tb {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__left,
  &__right {
    margin-bottom: 1rem;

    @include screen-tb {
      width: calc(50% - (1.5rem * 0.6));
      margin-bottom: calc(4rem * 0.6);
    }

    @include screen-pc {
      width: calc(50% - 1.5rem);
      margin-bottom: 4rem;
    }
  }

  // left
  &__stock {
    border: 2px solid $blue;
    flex-direction: column-reverse;
    position: relative;
    background-color: $white;

    @include screen-tb {
      flex-direction: row;
    }
  }

  &__stock-left,
  &__stock-right {
    width: 100%;

    @include screen-tb {
      width: 50%;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__stock-left {
    padding: 0 2.5rem 3.2rem;

    @include screen-tb {
      padding: calc(6.5rem * 0.6) calc(3.5rem * 0.6) calc(6rem * 0.6) calc(7rem * 0.6);
    }

    @include screen-pc {
      padding: 6.5rem 3.5rem 6rem 7rem;
    }
  }

  &__stock-ttl-en {
    font-size: 3.484rem;
    white-space: nowrap;
    letter-spacing: -0.08em;
    display: inline-block;

    @include screen-tb {
      font-size: calc(5.8rem * 0.6);
      line-height: 1;
      display: block;
    }

    @include screen-pc {
      font-size: 5.8rem;
    }
  }

  &__stock-ttl-jp {
    font-size: 1.6rem;
    display: inline-block;
    line-height: 1;
    margin: 0 0 0 1.5rem;

    @include screen-tb {
      display: block;
      font-size: calc(2rem * 0.6);
      margin-bottom: calc(1.5rem * 0.6);
      line-height: 1.5;
      margin: 0;
    }

    @include screen-pc {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  }

  &__stock-desc {
    line-height: 1.7;
    margin-bottom: 1.2rem;
    font-size: 1.4rem;

    @include screen-tb {
      line-height: 1.8;
      font-size: calc(1.4rem * 0.6);
      margin-bottom: calc(3.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
      margin-bottom: 3.5rem;
    }
  }

  &__stock-button {
    width: 24rem;
    max-width: 100%;
    height: 5rem;
    line-height: 5rem;
    display: block;
    padding: 0 2rem;
    font-size: 1.4rem;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      width: calc(24rem * 0.6);
      height: calc(5rem * 0.6);
      line-height: calc(5rem * 0.6);
      padding: 0 calc(2rem * 0.6);
      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      width: 24rem;
      height: 5rem;
      line-height: 5rem;
      padding: 0 2rem;
      font-size: 1.6rem;
    }
  }

  &__stock-right {
    position: relative;

    img {
      @include screen-tb {
        @include center(xy);
      }
    }
  }

  // right
  &__list {
    margin-bottom: 1rem;

    @include screen-tb {
      margin-bottom: 0;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 1rem;

      @include screen-tb {
        margin-bottom: calc(1.8rem * 0.6);
      }

      @include screen-pc {
        margin-bottom: 1.8rem;
      }
    }
  }
  &__link {
    border: 2px solid $blue;
    display: block;

    &:hover {
      opacity: 0.5;
    }

    @include screen-tb {
      // padding: 2rem;
    }

    &:after {
      @include absolute($top: 50%, $right: 1.5rem);

      @include screen-tb {
        @include absolute($top: 50%, $right: calc(3rem * 0.6));
      }

      @include screen-pc {
        @include absolute($top: 50%, $right: 3rem);
      }
    }
  }
  &__link-flex {
    align-items: center;
    background-color: $white;

    @include screen-tb {
      padding-left: calc(1.5rem * 0.6);
    }

    @include screen-pc {
      padding-left: 1.5rem;
    }
  }
  &__ico {
    width: 11.5rem;
    height: 10.6rem;
    flex-shrink: 0;
    position: relative;

    @include screen-tb {
      width: calc(11.8rem * 0.6);
      height: calc(11.8rem * 0.6);
    }

    @include screen-pc {
      width: 11.8rem;
      height: 11.8rem;
    }

    picture {
      @include center(xy);
      display: block;

      img {
        display: block;
      }
    }
  }

  &__ico1 {
    picture {
      img {
        display: block;
        width: 7.9rem;
        height: 4.3rem;

        @include screen-tb {
          width: calc(7.9rem * 0.6);
          height: calc(4.3rem * 0.6);
        }
        @include screen-pc {
          width: 7.9rem;
          height: 4.3rem;
        }
      }
    }
  }

  &__ico2 {
    picture {
      img {
        display: block;
        width: 8.9rem;
        height: 4.4rem;

        @include screen-tb {
          width: calc(8.9rem * 0.6);
          height: calc(4.4rem * 0.6);
        }

        @include screen-pc {
          width: 8.9rem;
          height: 4.4rem;
        }
      }
    }
  }

  &__ico3 {
    picture {
      img {
        display: block;
        width: 6.8rem;
        height: 6rem;

        @include screen-tb {
          width: calc(6.8rem * 0.6);
          height: calc(6rem * 0.6);
        }

        @include screen-pc {
          width: 6.8rem;
          height: 6rem;
        }
      }
    }
  }

  &__list-cont {
    @include screen-tb {
      padding-left: calc(1rem * 0.6);
    }

    @include screen-pc {
      padding-left: 1rem;
    }
  }

  &__list-ttl {
    font-size: 1.9rem;
    @include screen-tb {
      font-size: calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 2rem;
    }
  }

  &__list-desc {
    font-size: 1.3rem;
    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
    }
  }

  //buttons
  &__buttons {
  }

  &__buttons-flex {
    flex-direction: row;
    justify-content: space-between;
  }

  &__buttons-left,
  &__buttons-right {
    width: calc(50% - 0.5rem);

    @include screen-tb {
      width: calc(50% - (1.5rem * 0.6));
      margin-bottom: calc(4rem * 0.6);
    }

    @include screen-pc {
      width: calc(50% - 1.5rem);
      margin-bottom: 4rem;
    }
  }

  &__button {
    background-color: rgba($color: #000000, $alpha: 0.3);
    height: 6rem;
    line-height: 6rem;
    font-size: 1.6rem;
    text-align: center;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      height: calc(7.3rem * 0.6);
      line-height: calc(7.3rem * 0.6);
      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      height: 7.3rem;
      line-height: 7.3rem;
      font-size: 1.6rem;
    }

    &:after {
      @include absolute($top: 50%, $right: 1.5rem);

      @include screen-tb {
        @include absolute($top: 50%, $right: calc(3rem * 0.6));
        font-size: calc(1.8rem * 0.6);
      }

      @include screen-pc {
        @include absolute($top: 50%, $right: 3rem);
        font-size: 1.8rem;
      }
    }
  }
}

// recommend
.top-recommend {
  @extend .page;

  &__smoke {
    @include background-image("smoke_SP.png");
    @include absolute($top: 0, $left: 0);
    width: 100%;
    height: 100%;
    display: block;
    background-size: contain;
    background-position: bottom center;

    @include screen-tb {
      @include background-image("smoke_PC.png");
      background-size: contain;
      background-position: bottom center;
    }
  }

  &__bg-elm {
    @include absolute($top: 0, $left: 0);
    width: 100%;
    height: 100%;

    &:before,
    &:after {
      @include absolute($top: 0, $left: 0);
      width: 50%;
      height: 100%;
      content: "";
      display: block;
    }

    &:before {
      @include absolute($top: 15rem, $left: 0);
      background-color: rgba($color: #202387, $alpha: 0.15);
      height: 72%;

      @include screen-tb {
        @include absolute($top: calc(7rem * 0.6), $left: 0);
        height: calc(100% - calc(7.1rem * 0.6));
      }

      @include screen-pc {
        @include absolute($top: 7rem, $left: 0);
        height: calc(100% - 7.1rem);
      }
    }

    &:after {
      @include absolute($top: 15rem, $left: 50%);
      height: 72%;
      background-color: rgba($color: #000000, $alpha: 0.15);

      @include screen-tb {
        @include absolute($top: calc(35rem * 0.6), $left: 50%);
        height: calc(100% - calc(35.1rem * 0.6));
      }

      @include screen-pc {
        @include absolute($top: 35rem, $left: 50%);
        height: calc(100% - 35.1rem);
      }
    }
  }

  &__bg-elm2 {
    @include center(xy);
    background-color: $white;
    width: 74%;
    height: 100%;
    transform: translateY(-50%) translateX(-78%) skewX(20deg);

    @include screen-tb {
      @include center(xy);
      background-color: $white;
      width: 48%;
      height: 100%;
      transform: translateY(-50%) translateX(-60%) skewX(20deg);
    }
  }

  &__wrap {
    position: relative;
    padding: 2rem 0 2.5rem 0;

    @include screen-tb {
      padding: 0 0 calc(11rem * 0.6);
    }

    @include screen-pc {
      padding: 0 0 11rem;
    }
  }

  &__container {
    max-width: 146rem;
    padding: 0 2rem;

    @include screen-tb {
      max-width: calc(146rem * 0.6);
      padding: 0 4rem;
    }

    @include screen-pc {
      max-width: 158rem;
      padding: 0 8rem;
    }
  }

  &__ttl-en {
    text-align: center;
    font-size: 3.4rem;
    letter-spacing: 0.08em;

    @include screen-tb {
      font-size: calc(6.1rem * 0.6);
    }

    @include screen-pc {
      font-size: 6.1rem;
    }

    span.char {
      &:nth-child(1) {
        color: $red;
      }

      @include screen-tb {
        letter-spacing: calc(3px * 0.6);
      }

      @include screen-pc {
        letter-spacing: 3px;
      }
    }
  }

  &__ttl-jp {
    text-align: center;
    margin-bottom: 2rem;

    // inview
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: all 0.5s $gnav-easing;
    will-change: opacity, transform;

    .inview & {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }

    @include screen-tb {
      font-size: calc(2.2rem * 0.6);
      margin-bottom: calc(3rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.2rem;
      margin-bottom: 3rem;
    }
  }

  &__gallery-wrap {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0 2rem;
    margin-bottom: 2rem;

    @include screen-tb {
      padding: 0;
      margin-bottom: 0;
    }
  }

  &__gallery {
    @include screen-tb {
      margin-bottom: calc(4.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 4.5rem;
    }
  }

  &__list {
    justify-content: space-between;
  }

  &__item {
    @include screen-tb {
      width: calc(33.5rem * 0.6);
      height: auto;
    }

    @include screen-pc {
      width: 33.5rem;
    }
  }

  &__link {
    display: block;

    &:hover {
      opacity: 0.8;
    }
  }

  &__img {
    margin-bottom: 0.8rem;

    @include screen-tb {
      margin-bottom: calc(1rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 1rem;
    }

    picture,
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__car-name {
    font-size: $bold;
    font-size: 1.8rem;
    margin-bottom: 0.5rem;

    @include screen-tb {
      font-size: calc(1.8rem * 0.6);
      margin-bottom: calc(0.4rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.8rem;
      margin-bottom: 0.4rem;
    }
  }

  &__desc {
    font-size: 1.4rem;
    letter-spacing: 0;

    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
    }
  }

  &__price {
    font-family: $num;
    font-weight: $semi-bold;
    color: $red;
    font-size: 2.8rem;
    @include screen-tb {
      font-size: calc(2.8rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.8rem;
    }

    span {
      font-size: 1.2rem;
      @include screen-tb {
        font-size: calc(1.2rem * 0.6);
        color: $red;
      }

      @include screen-pc {
        font-size: 1.2rem;
      }
    }
  }

  &__gallery-prev,
  &__gallery-next {
    transform: translateY(-50%);
    width: 1.6rem;
    height: 3rem;
    cursor: pointer;

    @include screen-tb {
      width: calc(2.9rem * 0.6);
      height: calc(5.4rem * 0.6);
    }

    @include screen-pc {
      width: 2.9rem;
      height: 5.4rem;
    }
  }

  &__gallery-next {
    @include absolute($top: 35%, $right: -1rem);
    @include background-image("arrow-sli2.svg");

    @include screen-tb {
      @include absolute($top: 43%, $right: calc(-6rem * 0.6));
    }

    @include screen-pc {
      @include absolute($top: 43%, $right: -6rem);
    }
  }

  &__gallery-prev {
    @include absolute($top: 35%, $left: -1rem);
    @include background-image("arrow-sli.svg");

    @include screen-tb {
      @include absolute($top: 43%, $left: calc(-6rem * 0.6));
    }

    @include screen-pc {
      @include absolute($top: 43%, $left: -6rem);
    }
  }

  &__button {
    background-color: rgba($color: #000000, $alpha: 0.3);
    width: 100%;
    height: 5rem;
    line-height: 5rem;
    max-width: 96rem;
    text-align: center;
    margin: 0 auto;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      height: calc(7.3rem * 0.6);
      line-height: calc(7.3rem * 0.6);

      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      height: 7.3rem;
      line-height: 7.3rem;
      font-size: 1.6rem;
    }
  }

  @include screen-pc {
  }

  &:after {
    @include absolute($top: 50%, $right: 1.5rem);

    @include screen-tb {
      @include absolute($top: 50%, $right: calc(3rem * 0.6));
      font-size: calc(1.8rem * 0.6);
    }

    @include screen-pc {
      @include absolute($top: 50%, $right: 3rem);
      font-size: 1.8rem;
    }
  }
}

// news&magazine
.top-info {
  @extend .page;

  &__wrap {
    padding: 5.5rem 0 0;
    @include screen-tb {
      padding: 0;
    }
  }

  &__container {
  }

  &__ttl-en {
    text-align: center;
    font-size: 3.4rem;
    letter-spacing: 0em;

    @include screen-tb {
      font-size: calc(6.1rem * 0.6);
    }

    @include screen-pc {
      font-size: 6.1rem;
    }

    span.char {
      &:nth-child(1),
      &:nth-child(8) {
        color: $red;
      }

      &:nth-child(5),
      &:nth-child(7) {
        font-size: 0;
      }

      @include screen-tb {
        letter-spacing: calc(3px * 0.6);
      }

      @include screen-pc {
        letter-spacing: 3px;
      }
    }
  }

  &__ttl-jp {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 2rem;

    // inview
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: all 0.5s $gnav-easing;
    will-change: opacity, transform;

    .inview & {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }

    @include screen-tb {
      font-size: calc(2.2rem * 0.6);
      margin-bottom: calc(2.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.2rem;
      margin-bottom: 2.5rem;
    }
  }

  &__flex {
    flex-direction: column;

    @include screen-tb {
      flex-direction: row;
    }
  }

  &__news,
  &__magazine {
    width: 100%;

    @include screen-tb {
      width: 50%;
    }
  }

  // news
  &__news {
    @include background-image("new-bg.jpg");
    padding: 3.5rem 0 5rem;

    @include screen-tb {
      padding: calc(9rem * 0.6) 5.5%;
    }

    @include screen-pc {
      padding: 9rem 5.5%;
    }
  }

  &__news-ttl {
    text-align: center;
    font-size: 2.6rem;
    margin-bottom: 1rem;

    @include screen-tb {
      font-size: calc(3.5rem * 0.6);
      margin-bottom: calc(1.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 3.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &__news-list {
    border-top: 1px solid $gray;
    margin-bottom: 3rem;

    @include screen-tb {
      margin-bottom: calc(3.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 3.5rem;
    }
  }

  &__news-item {
    border-bottom: 1px solid $gray;
    padding: 1.5rem;

    @include screen-tb {
      padding: 0;
    }
  }

  &__news-link {
    display: block;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      display: flex;
      padding: calc(2.9rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 2.9rem 0;
    }
  }

  &__news-date,
  &__news-cat,
  &__news-item-ttl {
    font-size: 1.5rem;

    @include screen-tb {
      font-size: calc(1.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.5rem;
    }
  }

  &__news-date {
    flex-shrink: 0;
    display: inline-block;
    font-size: 1.2rem;
    margin: 0 1rem 1rem 0;

    @include screen-tb {
      font-size: calc(1.5rem * 0.6);
      margin: 0;
    }

    @include screen-pc {
      font-size: 1.5rem;
    }
  }

  &__news-cat {
    background-color: $blue;
    color: $white;
    display: inline-block;
    font-size: 1.2rem;
    padding: 0.2rem 1rem;
    margin: 0 0 1rem 0;
    line-height: 1;
    text-align: center;
    min-width: 9.2rem;

    @include screen-tb {
      flex-shrink: 0;
      min-width: calc(10rem * 0.6);
      margin: 0 calc(1.5rem * 0.6);
      padding: calc(0.2rem * 0.6) calc(2rem * 0.6);
      max-height: calc(2.5rem * 0.6);
      font-size: calc(1.4rem * 0.6);
      line-height: 1.1;
    }

    @include screen-pc {
      min-width: 10rem;
      margin: 0 1.5rem;
      padding: 0.2rem 0.5rem;
      max-height: 2.1rem;
      font-size: 1.4rem;
      line-height: 1;
    }
  }

  &__news-button {
    background-color: rgba($color: #000000, $alpha: 0.3);
    width: calc(100% - 4rem);
    height: 5rem;
    line-height: 5rem;
    margin: 0 auto;
    text-align: center;
    font-size: 1.4rem;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      width: 100%;
      height: calc(5rem * 0.6);
      line-height: calc(5rem * 0.6);

      font-size: calc(1.6rem * 0.6);
      max-width: 32rem;
    }

    @include screen-pc {
      height: 5rem;
      line-height: 5rem;
      font-size: 1.6rem;
    }
  }

  // magazine
  &__magazine {
    @include background-image("maga-bg.jpg");
    padding: 5rem 0 6rem;

    @include screen-tb {
      padding: calc(8rem * 0.6) 5.5%;
    }

    @include screen-pc {
      padding: 8rem 5.5%;
    }
  }

  &__magazine-ttl {
    margin: 0 auto;
    width: 32.3rem;
    height: 5.9rem;
    margin-bottom: 2rem;

    @include screen-tb {
      width: calc(43rem * 0.6);
      height: calc(7.8rem * 0.6);
      margin: 0 auto calc(2rem * 0.6);
    }

    @include screen-pc {
      width: 43rem;
      height: 7.8rem;
      margin: 0 auto 2rem;
    }
  }

  &__magazine-list {
    margin-bottom: 4.5rem;
    padding: 0 2rem;

    @include screen-tb {
      margin-bottom: calc(3.5rem * 0.6);
      padding: 0;
    }

    @include screen-pc {
      margin-bottom: 3.5rem;
    }
  }

  &__magazine-item {
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
      @include screen-tb {
        margin-bottom: calc(1rem * 0.6);
      }

      @include screen-pc {
        margin-bottom: 1rem;
      }
    }

    &.--new {
      &:after {
        @include background-image("ico-new.svg");
        @include absolute($right: 0.5rem, $top: -1rem);
        content: "";
        display: block;
        width: 3.5rem;
        height: 3.5rem;

        @include screen-tb {
          @include absolute($right: 0rem, $top: calc(-2rem * 0.6));
          width: calc(5.7rem * 0.6);
          height: calc(5.7rem * 0.6);
        }

        @include screen-pc {
          @include absolute($right: 0rem, $top: -2rem);
          width: 5.7rem;
          height: 5.7rem;
        }
      }
    }
  }

  &__magazine-link {
    width: 100%;
    height: auto;
    background-color: $white;
    min-height: 10rem;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      min-height: calc(14rem * 0.6);
    }

    @include screen-pc {
      min-height: 14rem;
    }
  }

  &__magazine-cont {
    width: calc(100% - 17rem);
    height: auto;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;

    @include screen-tb {
      width: calc(100% - calc(26.5rem * 0.6));
      padding: calc(1.5rem * 0.6);
    }

    @include screen-pc {
      width: calc(100% - 26.5rem);
      padding: 1.5rem;
    }
  }

  &__magazine-num-cat {
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  &__magazine-num {
    font-size: 1.8rem;
    width: auto;
    font-family: $num;
    font-weight: $semi-bold;
    color: $orange;
    align-items: center;
    flex-shrink: 0;
    margin: 0 1rem 0 0;

    @include screen-tb {
      font-size: calc(2.6rem * 0.6);
      margin: 0 calc(1rem * 0.6) 0 0;
    }

    @include screen-pc {
      font-size: 2.6rem;
      margin: 0 1rem 0 0;
    }

    span {
      font-size: 1rem;
      border-top: 1px solid $orange;
      border-bottom: 1px solid $orange;
      font-family: $num;
      font-weight: $semi-bold;
      display: inline-block;
      color: $orange;
      margin-right: 1rem;

      @include screen-tb {
        font-size: calc(1.45rem * 0.6);
        margin-right: calc(1rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.45rem;
        margin-right: 1rem;
      }
    }
  }

  &__magazine-cat {
    background-color: #e5e5e5;
    flex-shrink: 0;
    font-size: 1rem;
    padding: 0.2rem 0.5rem;

    @include screen-tb {
      font-size: calc(1.3rem * 0.6);
      padding: calc(0.25rem * 0.6) calc(0.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.3rem;
      padding: 0.25rem 0.5rem;
    }
  }

  &__magazine-img {
    // @include background-image("maga1.jpg");
    width: 17rem;
    height: auto;
    position: relative;
    background-size: cover;
    background-position: center center;

    @include screen-tb {
      width: calc(26.5rem * 0.6);
    }

    @include screen-pc {
      width: 26.5rem;
    }

    &:after {
      @include background-image("new-magazine-triangle.svg");
      @include absolute($top: 0, $left: -0.1rem);
      background-size: 100% 100%;
      background-position: left center;
      width: 2.5rem;
      height: 100%;
      content: "";
      display: block;

      @include screen-tb {
        width: calc(2.5rem * 0.6);
      }

      @include screen-pc {
        width: 2.5rem;
        width: 10%;
      }
    }
  }

  &__magazine-article-ttl {
    font-weight: $bold;
    line-height: 1.25;
    padding-left: 1rem;
    @include screen-tb {
      font-size: calc(2.4rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.4rem;
    }
  }

  &__magazine-button {
    width: calc(100% - 4rem);
    height: 5rem;
    line-height: calc(5rem - 2px);
    list-style: none;
    margin: 0 auto;
    text-align: center;
    border: 1px solid $white;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      width: 100%;
      max-width: 32rem;
      height: calc(5rem * 0.6);
      line-height: calc(5rem * 0.6);

      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      height: 5rem;
      line-height: 5rem;
      font-size: 1.6rem;
    }
  }
}

// banner
.shop-banner {
  @extend .page;

  &__wrap {
    padding: 5.5rem 0 6rem;

    @include screen-tb {
      padding: calc(7.5rem * 0.6) 0 calc(5.8rem * 0.6);
    }

    @include screen-pc {
      padding: 7.5rem 0 5.8rem;
    }
  }
  &__container {
    padding: 0 2rem;

    @include screen-pc {
      padding: 0;
    }
  }
  &__link {
    @include background-image("banner-shop.jpg");
    @include absolute($top: 0, $left: 0);
    max-width: 96rem;
    position: relative;
    display: block;
    margin: 0 auto;

    &:hover {
      opacity: 0.8;
    }

    &:after {
      content: "";
      @include aspect-ratio(372px, 90px);

      @include screen-tb {
        @include aspect-ratio(960px, 170px);
      }
    }
  }
  &__ttl {
    @include absolute($top: 50%, $left: 0);
    transform: translateY(-50%);
    width: 22.8rem;
    height: 3.8rem;
    left: 2.5rem;

    @include screen-tb {
      width: calc(42.6rem * 0.6);
      height: calc(7.1rem * 0.6);
      left: calc(4.5rem * 0.6);
    }

    @include screen-pc {
      width: 42.6rem;
      height: 7.1rem;
      left: 4.5rem;
    }
  }

  &__arrow {
    @include absolute($top: 50%, $right: 2.5rem);
    transform: translateY(-50%);
    width: 2.6rem;
    height: 2.6rem;

    @include screen-tb {
      width: calc(4.9rem * 0.6);
      height: calc(4.9rem * 0.6);
      right: calc(3.5rem * 0.6);
    }

    @include screen-pc {
      width: 4.9rem;
      height: 4.9rem;
      right: 3.5rem;
    }
  }
}

// sns
.sns {
  @extend .page;

  &__wrap {
    padding-bottom: 4.5rem;
    @include screen-tb {
      padding-bottom: calc(9rem * 0.6);
    }

    @include screen-pc {
      padding-bottom: 9rem;
    }
  }
  &__container {
    max-width: 96rem;
    padding: 0 2rem;

    @include screen-pc {
      padding: 0;
    }
  }
  &__ttl-en {
    text-align: center;
    font-size: 3.4rem;
    letter-spacing: 0em;

    @include screen-tb {
      font-size: calc(6.1rem * 0.6);
    }

    @include screen-pc {
      font-size: 6.1rem;
    }

    span.char {
      &:nth-of-type(1),
      &:nth-of-type(7),
      &:nth-of-type(11) {
        color: $red;
      }

      &:nth-of-type(4),
      &:nth-of-type(6) {
        font-size: 0;
      }

      @include screen-tb {
        letter-spacing: calc(3px * 0.6);
      }

      @include screen-pc {
        letter-spacing: 3px;
      }
    }
  }

  &__ttl-jp {
    text-align: center;
    margin-bottom: 1rem;

    // inview
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: all 0.5s $gnav-easing;
    will-change: opacity, transform;

    .inview & {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }

    @include screen-tb {
      font-size: calc(2.2rem * 0.6);
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.2rem;
      margin-bottom: 2rem;
    }
  }

  &__flex {
    flex-direction: column;

    @include screen-tb {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__facebook,
  &__social-ico {
    width: 100%;
    flex-shrink: 0;

    @include screen-tb {
      width: calc(50% - (1.8rem * 0.6));
    }

    @include screen-pc {
      width: calc(50% - 1.8rem);
    }
  }

  &__facebook {
    width: 100%;
    height: auto;
    margin-bottom: 2.5rem;

    @include screen-tb {
      width: calc(50% - (1.8rem * 0.6));
      height: 40.2rem;
      margin-bottom: 0;
    }

    @include screen-pc {
      width: calc(50% - 1.8rem);
      height: 40.2rem;
    }

    > .fb-page {
      width: 100%;
    }

    > .fb-page > span,
    iframe {
      width: 100% !important;
    }
  }

  &__social-ico-list {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__social-ico-item {
    width: calc(50% - 0.5rem);
    height: auto;
    position: relative;
    margin-bottom: 1rem;

    &:hover {
      opacity: 0.8;
    }

    &:after {
      content: "";
      display: block;
      @include aspect-ratio(364px, 228px);

      @include screen-tb {
        @include aspect-ratio(225px, 191px);
      }
    }

    // @include screen-tb {
    //   width: calc(22.5rem * 0.6);
    //   height: calc(19.1rem * 0.6);
    //   margin-bottom: calc(1rem * 0.6);
    // }

    // @include screen-pc {
    //   width: 22.5rem;
    //   height: 19.1rem;
    //   margin-bottom: 1rem;
    // }

    img {
      @include absolute($top: 0, $left: 0);
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

// pay
.payment {
  @extend .page;
  @include background-image("new-bg.jpg");

  border-top: 2px solid $black;

  &__wrap {
    padding: 5rem 0 8rem 0;
    @include screen-tb {
      padding: calc(7rem * 0.6) 0 calc(8.5rem * 0.6);
    }

    @include screen-pc {
      padding: 7rem 0 8.5rem;
    }
  }

  &__container {
    max-width: 96rem;
    padding: 0 2rem;

    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;

    @include screen-tb {
      font-size: calc(3rem * 0.6);
      margin-bottom: calc(1rem * 0.6);
    }

    @include screen-pc {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }

  &__desc {
    font-size: 1.4rem;
    margin-bottom: 3.5rem;

    @include screen-tb {
      text-align: center;
      font-size: calc(1.4rem * 0.6);
      margin-bottom: calc(4.7rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
      margin-bottom: 4.7rem;
    }
  }

  &__flex {
    flex-direction: column;

    @include screen-tb {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__left,
  &__right {
    width: 100%;
    background-color: $white;
    padding: 2rem 2.5rem;
    border: 2px solid $blue;

    @include screen-tb {
      width: calc(50% - (2rem * 0.6));
      border: 4px solid $blue;
      padding: calc(2rem * 0.6) calc(2.5rem * 0.6);
    }

    @include screen-pc {
      width: calc(50% - 2rem);
      border: 4px solid $blue;
      padding: 2rem 2.5rem;
    }
  }

  // left
  &__left {
    margin-bottom: 1rem;
    @include screen-tb {
      margin-bottom: 0;
    }
  }

  &__left-ttl {
    margin-bottom: 1rem;
    font-size: 1.55rem;

    @include screen-tb {
      font-size: calc(1.55rem * 0.6);
      font-weight: $bold;
      margin-bottom: 1rem;
    }

    @include screen-pc {
      font-size: 1.55rem;
    }
  }

  &__left-list {
    border-bottom: 1px solid #b2b2b2;
    margin-bottom: 2rem;

    @include screen-tb {
      margin-bottom: calc(2rem * 0.6);
      padding-bottom: calc(1rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 2rem;
      padding-bottom: 1rem;
    }
  }

  &__left-item {
    align-items: center;
    margin-bottom: 1rem;
  }

  &__left-corp {
    font-size: 1.2rem;
    margin-right: 1rem;

    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
    }
    @include screen-pc {
      font-size: 1.4rem;
    }
  }

  &__left-img {
    flex-shrink: 0;
    margin: 0;

    @include screen-tb {
      margin-right: calc(0.5rem * 0.6);
    }

    @include screen-pc {
      margin-right: 0.5rem;
    }

    img {
      display: block;
    }
  }

  &__left-name {
    font-size: 1.2rem;
    white-space: nowrap;
    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
      white-space: normal;
    }

    @include screen-pc {
      font-size: 1.4rem;
    }
  }

  &__left-desc {
    font-size: 1.4rem;
    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.6rem;
    }
  }

  //right
  &__right-list {
  }

  &__right-item {
    &:not(:last-of-type) {
      border-bottom: 1px solid #b2b2b2;
      margin-bottom: 2rem;

      @include screen-tb {
        padding-bottom: calc(2rem * 0.6);
        margin-bottom: calc(2rem * 0.6);
      }

      @include screen-pc {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  &__right-ttl {
    font-size: 1.55rem;
    margin-bottom: 0.5rem;

    @include screen-tb {
      font-size: calc(1.55rem * 0.6);
      font-weight: $bold;
      margin-bottom: calc(1rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.55rem;
      font-weight: $bold;
      margin-bottom: 1rem;
    }
  }

  &__right-desc {
    font-size: 1.4rem;
    margin-bottom: 1rem;

    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
      margin-bottom: 0;
    }

    @include screen-pc {
      font-size: 1.4rem;
    }
  }
}

//about
.about {
  border-top: 2px solid $black;

  &__wrap {
    padding: 5rem 0 6rem 0;
    @include screen-tb {
      padding: 0 0 calc(9rem * 0.6);
    }

    @include screen-pc {
      padding: 0 0 9rem;
    }
  }

  &__container {
  }

  &__flex {
    flex-direction: column;

    @include screen-tb {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__left,
  &__right {
    width: 100%;

    @include screen-tb {
      width: 50%;
    }
  }

  &__left {
    margin-bottom: 6rem;
    @include screen-tb {
      padding: calc(8rem * 0.6) calc(9rem * 0.6) 0;
      margin: 0;
    }
    @include screen-pc {
      padding: 7.5rem 9rem 0;
    }
  }

  &__ttl {
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: 1.5rem;

    @include screen-tb {
      font-size: calc(2.4rem * 0.6);
      margin-bottom: calc(1rem * 0.6);
    }
    @include screen-pc {
      font-size: 2.4rem;
      margin-bottom: 1rem;
    }
  }

  &__dl {
    font-size: 1.4rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
    padding: 1.25rem 2rem;

    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
      padding: calc(1.25rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
      padding: 1.25rem;
    }

    &:nth-of-type(1) {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.3);
    }
  }

  &__dt {
    white-space: nowrap;
    width: 8.2rem;

    @include screen-tb {
      width: calc(14rem * 0.6);
    }

    @include screen-pc {
      width: 14rem;
    }
  }

  &__dd {
    width: calc(100% - 8.2rem);
    @include screen-tb {
      width: calc(100% - 14rem * 0.6);
    }
    @include screen-pc {
      width: calc(100% - 14rem);
    }
  }

  &__right {
  }

  &__map {
    width: 100%;
    height: auto;
    position: relative;

    &::after {
      @include aspect-ratio(412px, 396px);
      content: "";
      display: block;

      @include screen-tb {
        @include aspect-ratio(820px, 540px);
      }
    }

    iframe {
      @include absolute($top: 0, $left: 0);
      width: 100%;
      height: 100%;
    }
  }
}
