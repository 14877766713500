// privacy-main-img

.privacy-main-img {
  @extend .page;
  @include background-image("privacy-bg-sp.jpg");
  height: 36.5rem;

  @include screen-tb {
    @include background-image("privacy-bg.jpg");
    height: calc(46rem * 0.6);
  }

  @include screen-pc {
    height: 46rem;
  }

  &__wrap {
    padding: 12.5rem 0 0 0;
    @include screen-tb {
      padding: calc(20rem * 0.6) 0 0;
    }

    @include screen-pc {
      padding: 20rem 0 0;
    }
  }
  &__container {
    max-width: 128rem;

    padding: 0 2rem;

    @include screen-pc {
      padding: 0;
    }
  }

  &__ttl-area {
  }

  &__ttl-en {
    letter-spacing: 0;
    span {
      letter-spacing: 0;

      &:nth-child(1) {
        color: $red;
      }

      &:nth-child(7) {
        padding-right: .5rem;
        margin-right: .5rem;
        
        @include screen-tb {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  &__ttl-jp {
    @include screen-tb {
      margin-bottom: calc(2.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 2.5rem;
    }
  }

  &__desc {
    line-height: 2;
    font-size: 1.4rem;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
      text-align: center;
    }

    @include screen-pc {
      font-size: 1.6rem;
    }
  }
}

.private-policy {
  @extend .page;

  &__wrap {
    padding: 4rem 0 13.5rem;

    @include screen-tb {
      padding: calc(5rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 5rem 0;
    }
  }

  &__container {
    max-width: 64rem;
    padding: 0 2rem;
    @include screen-tb {
      padding: 0;
    }
  }

  &__ttl {
    font-size: 1.8rem;
    font-weight: $bold;
    text-align: center;
    margin-bottom: 3rem;

    @include screen-tb {
      font-size: calc(2.3rem * 0.6);

      margin-bottom: calc(4rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.3rem;
      margin-bottom: 4rem;
    }

    span {
      padding: 0 0.5rem;
      border-bottom: 1px solid $black;

      @include screen-tb {
        padding: 0 calc(0.5rem * 0.6);
      }

      @include screen-pc {
        padding: 0 0.5rem;
      }
    }
  }

  &__list {
    border-top: 1px solid $gray;
  }

  &__item {
    padding: 2rem 0 2.5rem;

    @include screen-tb {
      padding: calc(3rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 3rem 0;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $gray;
    }

    &:last-of-type {
      @include screen-tb {
        padding: calc(1.5rem * 0.6) 0;
      }

      @include screen-pc {
        padding: 1.5rem 0;
      }

      p.private-policy__item-desc {
        text-align: right;

        @include screen-tb {
          font-size: calc(1.6rem * 0.6);
        }

        @include screen-pc {
          font-size: 1.6rem;
        }
      }
    }
  }

  &__item-ttl {
    font-weight: $bold;
    font-size: 1.6rem;
    margin-bottom: 1rem;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
      margin-bottom: calc(1rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  }

  &__item-desc {
    font-size: 1.4rem;
    line-height: 2;
    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
    }
  }
}
