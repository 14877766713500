// contact-main-img

.zoom-img {
  @extend .page;
  @include background-image("zoom-bg.jpg");
  background-position: top center;
  background-size: cover;

  @include screen-tb {
  }

  &__wrap {
    padding: 12rem 0 7.7rem 0;
    @include screen-tb {
      padding: calc(22.5rem * 0.6) 0 calc(13.5rem * 0.6);
    }

    @include screen-pc {
      padding: 22.5rem 0 13.5rem;
    }
  }
  &__container {
    max-width: 132rem;
    padding: 0 2rem;
  }

  &__ttl-area {
    margin-bottom: 3.5rem;

    @include screen-tb {
      margin-bottom: calc(3.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 3.5rem;
    }
  }

  &__ttl-en {
    width: 20.8rem;
    height: 6.4rem;
    margin: 0 auto;
    position: relative;
    margin-bottom: 7rem;

    @include screen-tb {
      width: calc(53.8rem * 0.6);
      height: calc(4.4rem * 0.6);
      margin-bottom: calc(11rem * 0.6);
    }

    @include screen-pc {
      width: 53.8rem;
      height: 4.4rem;
      margin-bottom: 11rem;
    }

    &:after {
      @include absolute($left: 50%, $bottom: -5.5rem);
      transform: translateX(-50%);
      content: "";
      width: 1px;
      height: 3.8rem;
      background-color: $black;

      @include screen-tb {
        @include absolute($left: 50%, $bottom: calc(-9rem * 0.6));
        height: calc(5.4rem * 0.6);
      }

      @include screen-pc {
        @include absolute($left: 50%, $bottom: -9rem);
        height: 5.4rem;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__ttl-jp {
    text-align: center;
    font-weight: $bold;
    font-size: 2.6rem;
    line-height: 1.35;
    margin-bottom: 1rem;

    @include screen-tb {
      font-size: calc(3.2rem * 0.6);
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 3.2rem;
      margin-bottom: 2rem;
    }
  }

  &__desc {
    text-align: left;
    line-height: 1.85;
    font-size: 1.4rem;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
      text-align: center;
      line-height: 2;
    }

    @include screen-pc {
      font-size: 1.6rem;
    }
  }

  &__list {
    flex-direction: column;

    @include screen-tb {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__item {
    text-align: center;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    @include screen-tb {
      width: calc(33.3% - calc(2.5rem * 0.6));
      display: block;
      margin-bottom: 0;
    }

    @include screen-pc {
      width: calc(33.3% - 2.5rem);
    }
  }

  &__item-img {
    flex-shrink: 0;

    @include screen-tb {
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 2rem;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__item-ttl {
    font-size: 2rem;
    font-weight: $bold;
    text-align: left;
    padding-left: 2rem;

    @include screen-tb {
      font-size: calc(2.2rem * 0.6);
      text-align: center;
      padding: 0;
    }

    @include screen-pc {
      font-size: 2.2rem;
    }

    span {
      border-bottom: 2px solid #e60323;
    }
  }
}

// zoom-flow
.zoom-flow {
  background-color: #26284c;

  &__wrap {
    padding: 5.5rem 0 1rem 0;

    @include screen-tb {
      padding: calc(9rem * 0.6) 0 calc(6.5rem * 0.6);
    }

    @include screen-pc {
      padding: 9rem 0 6.5rem;
    }
  }

  &__container {
    max-width: 132rem;
    padding: 0 2rem;
  }

  &__ttl {
    color: $white;
    text-align: center;
    font-weight: $bold;
    font-size: 2.2rem;
    margin-bottom: 2.5rem;

    @include screen-tb {
      font-size: calc(3.2rem * 0.6);
      margin-bottom: calc(3rem * 0.6);
    }

    @include screen-pc {
      font-size: 3.2rem;
      margin-bottom: 3rem;
    }
  }

  &__list {
    flex-direction: column;

    @include screen-tb {
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: calc(6rem * 0.6);
    }
    @include screen-pc {
      margin-bottom: 6rem;
    }
  }

  &__item {
    position: relative;
    display: flex;
    margin-bottom: 5.5rem;
    align-items: center;

    @include screen-tb {
      width: calc(27.5rem * 0.6);
      height: auto;
      display: block;
      margin-bottom: 0;
    }

    @include screen-pc {
      width: 27.5rem;
    }

    &:not(:last-of-type) {
      &:after {
        content: "";
        display: block;

        @include screen-tb {
          @include background-image("arrow-zoom.svg");
          @include absolute($top: 28%, $bottom: auto, $left: auto, $right: calc(-3rem * 0.6));
          transform: translateY(-50%);
          width: calc(1.9rem * 0.6);
          height: calc(3.7rem * 0.6);
        }

        @include screen-pc {
          @include absolute($top: 33%, $left: auto, $right: -4rem);
          width: 1.9rem;
          height: 3.7rem;
        }
      }

      .zoom-flow__item-img {
        &:after {
          @include background-image("arrow-zoom-sp.svg");
          @include absolute($top: auto, $bottom: -2rem, $left: 50%, $right: auto);
          transform: translateX(-50%);
          content: "";
          display: block;
          width: 2.4rem;
          height: 1.3rem;

          @include screen-tb {
            display: none;
          }
        }
      }
    }
  }

  &__item-img {
    flex-shrink: 0;
    position: relative;

    @include screen-tb {
      margin-bottom: calc(1.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 1.5rem;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__item-cont {
    padding-left: 2rem;
    @include screen-tb {
      padding: 0;
    }
  }

  &__item-ttl {
    font-size: 1.6rem;
    color: $white;

    @include screen-tb {
      font-size: calc(1.8rem * 0.6);

      margin-bottom: calc(1.5rem * 0.6);
      text-align: center;
    }

    @include screen-pc {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
    }
  }

  &__item-desc {
    font-size: 1.4rem;
    color: $white;

    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
    }
  }

  &__preparation {
    background-position: left top;
    background-repeat: repeat-x;
    background-image: url("../img/border.png");
    padding: 4.5rem 0;

    @include screen-tb {
      padding: calc(4.5rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 4.5rem 0;
    }
  }

  &__preparation-ttl {
    text-align: center;
    color: #68d9ce;
    font-size: 2.2rem;
    margin-bottom: 1.5rem;

    @include screen-tb {
      font-size: calc(2.2rem * 0.6);
      margin-bottom: calc(1.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 2.2rem;
      margin-bottom: 1.5rem;
    }
  }

  &__preparation-desc {
    text-align: center;
    color: $white;
    font-size: 1.4rem;
    margin-bottom: 3rem;

    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
      margin-bottom: calc(2rem * 0.6);
    }
    @include screen-pc {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }
  }

  &__preparation-list {
    align-items: center;
    justify-content: center;
  }

  &__preparation-item {
  }

  &__preparation-link {
    width: 11.8rem;
    height: 4.6rem;
    line-height: 4.6rem;
    margin: 0 calc(0.5rem * 0.6);
    font-size: 1.4rem;
    background-color: $white;
    color: $blue;
    padding-left: 1rem;
    display: block;
    position: relative;

    &:hover{
      opacity: .8;
    }

    @include screen-tb {
      width: calc(22rem * 0.6);
      height: calc(6.3rem * 0.6);
      line-height: calc(6.3rem * 0.6);
      margin: 0 calc(0.5rem * 0.6);
      font-size: calc(2rem * 0.6);
      text-align: center;
      padding: 0;
    }

    @include screen-pc {
      width: 22rem;
      height: 6.3rem;
      line-height: 6.3rem;
      margin: 0 0.5rem;
      font-size: 2rem;
    }

    &:after {
      @include background-image("ico-download.svg");
      @include absolute($top: 50%, $right: 2rem);
      transform: translateY(-50%);
      display: block;
      content: "";
      width: calc(1.4rem * 0.8);
      height: calc(1.7rem * 0.8);

      @include screen-tb {
        @include absolute($top: 50%, $right: calc(2rem * 0.6));
        width: calc(1.4rem * 0.6);
        height: calc(1.7rem * 0.6);
      }

      @include screen-pc {
        @include absolute($top: 50%, $right: 2rem);
        width: 1.4rem;
        height: 1.7rem;
      }
    }
  }
}

// contact-form

.zoom-form {
  &__wrap {
    padding: 6.3rem 0 16rem 0;
    @include screen-tb {
      padding: calc(10rem * 0.6) 0 calc(10.5rem * 0.6);
    }

    @include screen-pc {
      padding: 10rem 0 10.5rem;
    }
  }
  &__container {
    padding: 0 2rem;
    max-width: 64rem;

    @include screen-pc {
      padding: 0;
    }
  }
  &__ttl {
    text-align: center;
    font-size: 2.4rem;
    font-weight: $bold;
    margin-bottom: 3.5rem;

    @include screen-tb {
      font-size: calc(3.2rem * 0.6);

      margin-bottom: calc(6rem * 0.6);
    }

    @include screen-pc {
      font-size: 3.2rem;
      margin-bottom: 6rem;
    }

    span {
      border-bottom: 1px solid $black;
    }
  }

  &__dl {
    flex-direction: column;

    border-top: 1px solid $gray;

    &:last-of-type {
      border-bottom: 1px solid $gray;
      align-items: flex-start;
      margin-bottom: 2rem;

      @include screen-tb {
        margin-bottom: calc(2.5rem * 0.6);
      }

      @include screen-pc {
        margin-bottom: 2.5rem;
      }
    }

    @include screen-tb {
      padding: calc(3rem * 0.6) 0;
      flex-direction: row;
      align-items: center;
    }

    @include screen-pc {
      padding: 3rem 0;
    }
  }

  &__dl-time {
    align-items: flex-start;
  }

  &__dt {
    flex-shrink: 0;
    font-size: 1.6rem;
    margin: 1rem 0;
    @include screen-tb {
      width: calc(20.8rem * 0.6);
      font-size: calc(1.6rem * 0.6);
      margin: 0;
    }

    @include screen-pc {
      width: 20.8rem;
      font-size: 1.6rem;
    }

    span {
      font-size: 1.2rem;
      border-radius: 0.5rem;
      display: inline-block;
      background-color: $blue;
      color: $white;
      padding: 0.2rem 1rem;
      margin-right: 0.5rem;

      @include screen-tb {
        font-size: calc(1.2rem * 0.6);
        border-radius: calc(0.5rem * 0.6);
        padding: calc(0.2rem * 0.6) calc(1rem * 0.6);
        margin-right: calc(0.5rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.2rem;
        border-radius: 0.5rem;
        padding: 0.2rem 1rem;
        margin-right: 0.5rem;
      }
    }

    span.choice {
      background-color: #989898;
    }
  }

  &__dd {
    margin-bottom: 3rem;
    @include screen-tb {
      width: calc(100% - calc(20.8rem * 0.6));
      margin-bottom: 0;
    }

    @include screen-pc {
      width: calc(100% - 20.8rem);
    }

    // form
    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      height: 40px;
      border: 0;
      padding: 10px;
      font-size: 16px;
      font-family: Arial, sans-serif;
      color: $black;
      border: solid 1px #808080;
      border-radius: 5px;
      background-color: #f1f1f1;
    }
    textarea {
      height: 140px;
    }
    select.zoom-plan,
    select.zoom-budget,
    select.zoom-device {
      width: 100% !important;
      padding: 0.5rem 1.5rem 0.5rem 1rem !important;
      border-radius: 5px !important;
    }
  }
  &__dd-time {
  }
  &__dd-time-flex {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-bottom: 1.25rem;
    

    @include screen-tb {
      margin-bottom: 1.5rem;
    }
  }
  &__dd-time-item {
    width: 28%;
    position: relative;
    margin-right: 3rem;

    @include screen-tb {
      padding: 0;
      margin-right: 3rem;
    }

    @include screen-pc {
      margin-right: 3rem;
    }

    span.time{
      @include absolute($right:-2.2rem, $top:.85rem);

      .mw_wp_form_confirm & {
        @include absolute($right:-2.2rem, $top:.0);
      }
    }

    span.error {
      // @include center(xy);
      // left: 0;
      width: 100%;
      display: inline-block;
      // transform: translateY(100%);
      white-space: nowrap;
    }
  }

  &__dd-time-select {
    select {
      width: 15rem;
      padding: 0.5rem 1.5rem 0.5rem 1rem;
      border-radius: 5px;
    }
  }
  &__privacy-check-box {
    margin-bottom: 2rem;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
  }

  &__privacy-check {
    line-height: 1;

    input[type="checkbox"] {
      margin-right: 13px;
    }

    span.mwform-checkbox-field {
      margin-bottom: 0.5rem;
      display: inline-block;
    }
  }

  &__privacy-desc {
    margin-bottom: 7rem;

    @include screen-tb {
      margin-bottom: calc(4.7rem * 0.6);
      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 4.7rem;
      font-size: 1.6rem;
    }
    a {
      color: $blue;
      border-bottom: 1px solid $gray;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column-reverse;

    @include screen-tb {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    input[type="submit"] {
      // 初期化
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none;
      border-radius: 0;

      width: 45%;
      height: 60px;
      border: 1px solid $red;
      padding: 15px 30px;
      margin: 0 auto;
      color: $white;
      background-color: $red;
      cursor: pointer;
      display: block;

      &:hover{
        opacity: .8;
      }
    }

    input.zoom-confirm {
      // 初期化
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none;
      border-radius: 0;

      background-color: $red;
      color: $white;
      width: 100%;

      &:hover{
        opacity: .8;
      }

      @include screen-tb {
        width: 45%;
      }
    }

    input.zoom-back {
      // 初期化
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none;
      border-radius: 0;

      background-color: $red;
      color: $white;
      width: 100%;
      margin-bottom: 1rem;

      &:hover{
        opacity: .8;
      }

      @include screen-tb {
        width: 45%;
        margin-bottom: 0;
      }
    }
  }
}
