/*--------------------------------------------
 found > base
---------------------------------------------*/
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// リキッド指定ここから
html {
  // 375px時のフォントサイズ
  font-size: 100/412 * 10vw;

  // Tablet（768px） ~
  @include screen-tb {
    font-size: 100/768 * 10vw;
  }

  // Pc（1280px） ~
  @include screen-pc {
    font-size: 62.5%;
  }
}
// リキッド指定ここまで、htmlに指定する。

body {
  font-size: 1.6rem;
  font-family: $jp-goth;
  font-weight: $regular;
  // line-height: 2;
  letter-spacing: 0.01em;

  // iPhone の Safari で文字サイズが勝手に大きくなる時の対処
  -webkit-text-size-adjust: 100%;

  @include screen-tb {
    /* 16px */
    font-size: 1.6rem;
  }
}

a {
  text-decoration: none;
  color: $black;
}

.page {
  width: 100%;
  height: auto;
  position: relative;

  &__header,
  &__main,
  &__container,
  &__wrapper,
  &__section,
  &__article,
  &__aside,
  &__footer {
    @extend .page;
  }

  // &__main {
  //   overflow: hidden;
  // }

  &__sp-none {
    display: none;
    @include screen-tb {
      display: block;
    }
  }

  &__pc-none {
    @include screen-tb {
      display: none;
    }
  }

  &__flex-box {
    @extend .page;
    display: flex;
  }

  &__container {
    margin: 0 auto;
  }

  // ttl
  &__ttl-med {
    font-family: $en-med;
  }

  &__ttl-bol {
    font-family: $en-bol;
  }

  &__ttl-jp-bol {
    font-family: $jp-min;
    font-weight: $bold;
  }

  // 固定ページ
  &__ttl-en {
    font-size: 4rem;
    text-align: center;
    font-size: 4rem;
    letter-spacing: 0.05em;
    line-height: 1;

    @include screen-tb {
      font-size: calc(7rem * 0.6);
    }

    @include screen-pc {
      font-size: 7rem;
    }

    &::first-letter {
      color: $red;
    }
  }

  &__ttl-jp {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 2rem;

    @include screen-tb {
      font-size: calc(2.2rem * 0.6);
      margin-bottom: 0;
    }

    @include screen-pc {
      font-size: 2.2rem;
    }
  }

  &__symbol {
    width: 100%;
    height: 2px;
    background-color: $black;
    position: relative;

    &:after {
      @include background-image("privacy-symbol.svg");
      @include center(xy);
      width: 1.9rem;
      height: 1.9rem;
      content: "";
      display: block;

      @include screen-tb {
        width: calc(2.6rem * 0.6);
        height: calc(2.6rem * 0.6);
      }

      @include screen-pc {
        width: 2.6rem;
        height: 2.6rem;
      }
    }
  }

  //btn
  &__btn-ar-s {
    position: relative;
    display: block;

    &:after {
      @include absolute($top: 50%, $right: 1.5rem);
      @include background-image("arrow-s.svg");
      transform: translateY(-50%);
      content: "";
      display: block;
      width: 1.9rem;
      height: 1.4rem;

      @include screen-tb {
        @include absolute($top: 50%, $right: calc(2rem * 0.6));
        width: calc(1.9rem * 0.6);
        height: calc(1.4rem * 0.6);
      }

      @include screen-pc {
        @include absolute($top: 50%, $right: 2rem);
        width: 1.9rem;
        height: 1.4rem;
      }
    }
  }

  &__btn-ar-m {
    position: relative;
    display: block;

    &:after {
      @include absolute($top: 50%, $right: 1.5rem);
      @include background-image("arrow-m.svg");
      transform: translateY(-50%);
      content: "";
      display: block;
      width: 1.9rem;
      height: 1rem;

      @include screen-tb {
        @include absolute($top: 50%, $right: calc(2rem * 0.6));
        width: calc(2.7rem * 0.6);
        height: calc(1.4rem * 0.6);
      }

      @include screen-pc {
        @include absolute($top: 50%, $right: 2rem);
        width: 2.7rem;
        height: 1.4rem;
      }
    }
  }

  &__btn-ar-l {
    position: relative;
    display: block;

    &:after {
      @include absolute($top: 50%, $right: 2rem);
      @include background-image("arrow-l.svg");
      transform: translateY(-50%);
      content: "";
      display: block;
      width: 1.9rem;
      height: 0.6rem;

      @include screen-tb {
        @include absolute($top: 50%, $right: calc(2rem * 0.6));
        width: calc(4.3rem * 0.6);
        height: calc(1.4rem * 0.6);
      }

      @include screen-tb {
        @include absolute($top: 50%, $right: 2rem);
        width: 4.3rem;
        height: 1.4rem;
      }
    }
  }

  // white
  &__color-white {
    color: $white;
  }

  &__bg-white {
    background-color: $white;
  }

  // black
  &__color-black {
    color: $black;
  }

  &__bg-black {
    background-color: $black;
  }

  // red
  &__color-red {
    color: $red;
  }

  &__bg-red {
    background-color: $red;
  }

  // blue
  &__color-blue {
    color: $blue;
  }

  &__bg-blue {
    background-color: $blue;
  }

  // gray
  &__color-gray {
    color: $gray;
  }

  &__bg-gray {
    background-color: $gray;
  }
}
