.header--magazine {
  @include fixed($top: 0, $left: 0);
  background-color: $white;

  .header__logo-link {
    span {
      @include background-image("logo-b.svg");
    }
  }
  .header__nav-item {
    &:before {
      @include screen-tb {
        @include background-image("nav-bar-b.svg");
      }
    }
  }

  .header__nav-link {
    @include screen-tb {
      color: $black;
    }
  }
}

.magazine-bg {
  @include absolute($top: 0, $left: 0);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-image: url("../img/magazine-archive-bg-sp.jpg");
  background-color: #26284c;

  @include screen-tb {
    @include absolute($top: calc(8rem * 0.6), $left: 0);
    height: calc(100% - 8rem * 0.6);
    background-image: url("../img/magazine-archive-bg.jpg");
  }

  @include screen-pc {
    @include absolute($top: 8rem, $left: 0);
    height: calc(100% - 8rem);
  }

  &--single {
    @include fixed($top: 0, $left: 0);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center top 6rem;
    background-repeat: no-repeat;

    @include screen-tb {
      background-position: center top calc(8rem * 0.6);
    }

    @include screen-pc {
      background-position: center top 8rem;
    }
  }
}

// magazine-archive-ttl
.magazine-archive-ttl {
  &__wrap {
    padding: 12.5rem 0 0;

    @include screen-tb {
      padding: calc(21rem * 0.6) 0 0;
    }

    @include screen-pc {
      padding: 21rem 0 0;
    }
  }
  &__container {
    max-width: 64rem;
    padding: 0 2rem;

    @include screen-tb {
      padding: 0;
    }
  }

  &__ttl-area {
    margin-bottom: 2.5rem;

    @include screen-tb {
      margin-bottom: calc(5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 5rem;
    }
  }

  &__img {
    width: 32.8rem;
    margin: 0 auto;
    position: relative;
    margin-bottom: 1.5rem;

    &:after {
      @include aspect-ratio(528px, 94px);
      content: "";
      display: block;
      margin-bottom: 0;
    }

    @include screen-tb {
      width: calc(52.8rem * 0.6);
      height: auto;
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      width: 52.8rem;
      margin-bottom: 2rem;
    }

    img {
      @include absolute($top: 0, $left: 0);
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__desc {
    font-size: 1.4rem;
    color: $white;
    line-height: 1.9;

    @include screen-tb {
      font-size: calc(1.6rem * 0.6);
      text-align: center;
      line-height: 2;
    }

    @include screen-pc {
      font-size: 1.6rem;
    }
  }
}

// magazine-archive-select
.magazine-archive-select {
  &__wrap {
  }
  &__container {
    max-width: 64rem;
    padding: 0 2rem;

    @include screen-tb {
      padding: 0;
    }
  }

  &__search {
    width: 100%;
    border-radius: 2.5rem;
    height: 5rem;
    border: 2px solid $black;
    margin: 0 auto;
    position: relative;

    @include screen-tb {
      width: 100%;
      border-radius: 3rem;
      height: 6rem;
    }
  }
  &__search-button {
    @include center(xy);
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: calc(5rem - 4px);
    display: block;
    text-align: center;
    z-index: 2;
    background-color: $white;
    border-radius: 2.5rem;
    cursor: pointer;

    @include screen-tb {
      border-radius: 3rem;
      line-height: calc(6rem - 4px);
    }

    &:after {
      @include absolute($top: 50%, $right: 2.5rem);
      content: "";
      display: block;
      background-image: url("../img/arrow-search.svg");
      transform: translateY(-50%);
      width: 1.8rem;
      height: 1.1rem;
      transition: transform 0.3s $gnav-easing;
    }

    &.is-active {
      &:after {
        transform: translateY(-50%) scale(1, -1);
      }
    }
  }
  &__search-list {
    width: calc(100% + 4px);
    height: auto;
    max-height: 20rem;
    border-radius: 0 0 3rem 3rem;
    border: 2px solid $black;
    margin: 2.5rem 0 0 -2px;
    padding: 2.5rem 0 1.5rem 0;
    background-color: $white;
    position: relative;
    z-index: 1;

    // scroll
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    // カルーセル用
    display: none;
    opacity: 0;
    transition: opacity 0.3s $gnav-easing;

    @include screen-tb {
      margin: 3rem 0 0 -2px;
      padding: 3rem 0 calc(2rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 3rem 0 2rem 0;
    }

    &.is-open {
      display: block;
      opacity: 1;
      animation: fade-in 0.3s $gnav-easing 0s;
    }
  }
  &__search-link {
    display: block;
    padding: 1rem 2rem;
    text-align: center;

    &:hover {
      opacity: 0.5;
    }

    @include screen-tb {
      padding: calc(2rem * 0.6) calc(2rem * 0.6);
    }

    @include screen-pc {
      padding: 1rem 2rem;
    }
  }
}

// magazine-archive
.magazine-archive {
  &__wrap {
    padding: 4.5rem 0 11rem;

    @include screen-tb {
      padding: calc(4.5rem * 0.6) 0 calc(11.5rem * 0.6);
    }

    @include screen-pc {
      padding: 4.5rem 0 11.5rem;
    }
  }
  &__container {
    max-width: 132rem;
    padding: 0 2rem;

    @include screen-pc {
      padding: 0;
    }
  }

  &__list {
    flex-direction: column;
    margin-bottom: 4rem;

    @include screen-tb {
      margin-bottom: calc(6rem * 0.6);
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    @include screen-pc {
      margin-bottom: 6rem;
    }
  }
  &__item {
    width: 100%;
    background-color: $white;
    margin-bottom: 1rem;
    position: relative;

    @include screen-tb {
      width: calc(25% - 4rem * 0.6);
      margin: 0 calc(2rem * 0.6) calc(4rem * 0.6);
      min-height: 0;
    }

    @include screen-pc {
      width: calc(25% - 4rem);
      margin: 0 2rem 4rem;
    }
  }

  &__item--new {
    &:after {
      @include background-image("ico-new.svg");
      @include absolute($right: 1rem, $top: -1rem);
      content: "";
      display: block;
      width: 3.5rem;
      height: 3.5rem;

      @include screen-tb {
        @include absolute($left: 0, $right: auto, $top: calc(-2rem * 0.6));
        width: calc(5.7rem * 0.6);
        height: calc(5.7rem * 0.6);
      }

      @include screen-pc {
        @include absolute($left: 0, $right: 0rem, $top: -2rem);
        width: 5.7rem;
        height: 5.7rem;
      }
    }
  }

  &__link {
    width: 100%;
    height: auto;
    flex-direction: row-reverse;
    position: relative;

    &:hover {
      opacity: 0.8;
    }

    @include screen-tb {
      display: block;
      height: 100%;
    }
  }

  &__img {
    background-size: cover;
    background-position: center center;
    width: 17rem;
    flex-shrink: 0;
    position: relative;

    @include screen-tb {
      @include aspect-ratio(290px, 184px);

      width: 100%;
      height: auto;
      position: relative;
    }

    &:after {
      @include background-image("new-magazine-triangle.svg");
      @include absolute($top: 0, $left: -1px);
      background-size: cover;
      background-position: left center;
      width: 2.5rem;
      height: 100%;
      content: "";
      display: block;

      @include screen-tb {
        @include background-image("magazine-triangle2.svg");
        @include absolute($top: auto, $bottom: -1px, $left: -0);
        background-size: cover;
        width: 100%;
        content: "";
        display: block;
        height: calc(1.8rem * 0.6);
      }

      @include screen-pc {
        height: 1.8rem;
      }
    }
  }

  &__item-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem 2rem;
    width: calc(100% - 17rem);

    @include screen-tb {
      display: block;
      padding: 0;
      width: auto;
    }
  }

  &__num-cat {
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.5rem;

    @include screen-tb {
      padding: calc(1rem * 0.6) calc(2rem * 0.6) 0 calc(2rem * 0.6);
      margin: 0;
    }

    @include screen-pc {
      padding: 1rem 2rem 0 2rem;
    }
  }

  &__num {
    font-size: 1.8rem;
    width: auto;
    font-family: $num;
    font-weight: $semi-bold;
    color: $orange;
    align-items: center;
    flex-shrink: 0;
    margin: 0 1rem 0 0;

    @include screen-tb {
      font-size: calc(2.6rem * 0.6);
      margin: 0 calc(2rem * 0.6) 0 0;
    }

    @include screen-pc {
      font-size: 2.6rem;
      margin: 0 2rem 0 0;
    }

    span {
      font-size: 1rem;
      border-top: 1px solid $orange;
      border-bottom: 1px solid $orange;
      font-family: $num;
      font-weight: $semi-bold;
      display: inline-block;
      color: $orange;
      margin-right: 0.5rem;
      letter-spacing: 0.08em;

      @include screen-tb {
        font-size: calc(1.45rem * 0.6);
        margin-right: calc(1rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.45rem;
        margin-right: 1rem;
      }
    }
  }

  &__cat {
    background-color: #e5e5e5;
    flex-shrink: 0;
    font-size: 1rem;
    padding: 0.1rem 0.4rem;

    @include screen-tb {
      font-size: calc(1.3rem * 0.6);
      padding: calc(0.25rem * 0.6) calc(0.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.3rem;
      padding: 0.25rem 0.5rem;
    }
  }

  &__ttl {
    font-weight: $bold;
    letter-spacing: -0.05em;
    line-height: 1.2;

    @include screen-tb {
      font-size: calc(2rem * 0.6);
      padding: 0 calc(2rem * 0.6) 0 calc(2rem * 0.6);
      margin-bottom: calc(8rem * 0.6);
      line-height: 1.45;
    }

    @include screen-pc {
      font-size: 2rem;
      padding: 0 2rem 0 2rem;
      margin-bottom: 8rem;
    }
  }

  &__more {
    @include absolute($left: 0, $bottom: 2.5rem);
    width: 100%;
    font-size: 1.2rem;

    @include screen-tb {
      padding: 0 calc(2rem * 0.6);
      font-size: calc(1.2rem * 0.6);
    }

    @include screen-pc {
      padding: 0 2rem;
      font-size: 1.2rem;
    }
    span {
      display: block;
      color: #808080;

      @include screen-tb {
        width: 100%;
        height: calc(4rem * 0.6);
        background-color: #f1f1f1;
        text-align: center;
        line-height: calc(4rem * 0.6);
      }

      @include screen-pc {
        height: 4rem;
        line-height: 4rem;
      }
    }
  }

  &__pager {
    margin: 0 auto;
    max-width: 32rem;
    @include screen-tb {
      max-width: calc(60rem * 0.6);
    }
    @include screen-pc {
      max-width: 60rem;
    }
    .wp-pagenavi {
      text-align: center;
      position: relative;

      .pages,
      .first,
      .last {
        display: none;
      }

      a,
      span {
        display: inline-block;
        width: 3.6rem;
        height: 3.6rem;
        line-height: 3rem;

        // @include screen-tb {
        //   width: 3.2rem;
        //   height: 3.2rem;
        //   line-height: 2.6rem;
        // }
      }
      a.smaller,
      a.larger,
      span.current {
        background-color: #a7a3a1;
        border: none;
        margin: 0.5rem;
        color: $white;

        // @include screen-tb {
        //   margin: 0 0.2rem;
        // }
      }
      a.previouspostslink {
        @include background-image("prev.svg");
        @include absolute($top: 50%, $left: -2.5rem);
        transform: translateY(-50%);
        text-indent: -9999px;

        // @include screen-tb {
        //   position: relative;
        //   top: 1px;
        //   transform: translateY(0);
        //   left: auto;
        // }
      }
      a.nextpostslink {
        @include background-image("next.svg");
        @include absolute($top: 50%, $right: -2.5rem);
        transform: translateY(-50%);
        text-indent: -9999px;

        // @include screen-tb {
        //   position: relative;
        //   top: 1px;
        //   transform: translateY(0);
        //   left: auto;
        //   right: auto;
        // }
      }
    }
  }
}

// magazine-single-ttl
.magazine-single-ttl {
  &__wrap {
    padding: 12.5rem 0 0;

    @include screen-tb {
      padding: calc(21rem * 0.6) 0 0;
    }

    @include screen-pc {
      padding: 21rem 0 0;
    }
  }
  &__container {
    max-width: 64rem;
    padding: 0 2rem;

    @include screen-tb {
      padding: 0;
    }
  }

  &__ttl-area {
    margin-bottom: 2rem;

    @include screen-tb {
      margin-bottom: calc(4rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 4rem;
    }
  }

  &__img {
    width: 32.8rem;
    margin: 0 auto;
    position: relative;
    margin-bottom: 1.5rem;

    &:after {
      @include aspect-ratio(528px, 94px);
      content: "";
      display: block;
      margin-bottom: 0;
    }

    @include screen-tb {
      width: calc(52.8rem * 0.6);
      height: auto;
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      width: 52.8rem;
      margin-bottom: 2rem;
    }

    img {
      @include absolute($top: 0, $left: 0);
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

// magazine_single
.magazine_single {
  &__wrap {
    padding: 0 0 11rem;

    @include screen-tb {
      padding: 0 0 calc(22rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 0 0 22rem 0;
    }
  }
  &__container {
    // max-width: calc(100% - 4rem);
    background-color: $white;
    padding: 3rem 2rem;

    @include screen-tb {
      max-width: calc(82rem * 0.6);
      padding: calc(4.5rem * 0.6) calc(4rem * 0.6);
    }

    @include screen-pc {
      padding: 4.5rem 4rem;
      max-width: 82rem;
    }
  }

  &__num-cat {
    align-items: center;
    justify-content: flex-start;

    @include screen-tb {
      // padding: 1rem 2rem 0 2rem;
    }
  }

  &__num {
    width: auto;
    font-family: $num;
    font-weight: $semi-bold;
    color: $orange;
    align-items: center;
    flex-shrink: 0;
    font-size: 2.7rem;
    margin-right: 1rem;

    @include screen-tb {
      font-size: calc(4rem * 0.6);
      margin: 0 calc(2rem * 0.6) 0 0;
    }

    @include screen-pc {
      font-size: 4rem;
      margin: 0 2rem 0 0;
    }

    span {
      font-size: 1.5rem;
      border-top: 1px solid $orange;
      border-bottom: 1px solid $orange;
      font-family: $num;
      font-weight: $semi-bold;
      display: inline-block;
      color: $orange;
      margin-right: 0.5rem;
      padding: 0 calc(1rem * 0.6);

      @include screen-tb {
        font-size: calc(2rem * 0.6);
        margin-right: calc(1rem * 0.6);
        padding: 0 calc(1rem * 0.6);
      }

      @include screen-pc {
        font-size: 2rem;
        margin-right: 1rem;
        padding: 0 1rem;
      }
    }
  }

  &__cat {
    background-color: #e5e5e5;
    flex-shrink: 0;
    font-size: 1.5rem;
    padding: 0.4rem;

    @include screen-tb {
      font-size: calc(2rem * 0.6);
      padding: calc(0.25rem * 0.6) calc(0.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 2rem;
      padding: 0.25rem 0.5rem;
    }
  }

  &__ttl {
    line-height: 1.4;
    font-size: 2.2rem;
    margin-bottom: 2rem;
    @include screen-tb {
      font-size: calc(3rem * 0.6);
      margin-bottom: calc(3rem * 0.6);
    }

    @include screen-pc {
      font-size: 3rem;
      margin-bottom: 3rem;
    }
  }

  &__share {
    width: 100%;
    height: 32px;
    margin-bottom: 1rem;
    font-size: 0;

    @include screen-tb {
      height: 32px;
      margin-bottom: calc(1.5rem * 0.6);
    }

    @include screen-pc {
      height: 32px;
      margin-bottom: 1.5rem;
    }

    // universal
    .addtoany_share {
      display: none;
    }

    //sns
    .a2a_button_twitter,
    .a2a_button_facebook,
    .a2a_button_line {
      width: 46px;
      height: 32px;

      span {
        display: none !important;
      }
    }
    .a2a_button_twitter {
      @include background-image("share-twitter.png");
      margin-right: 5px;

      &:hover {
        @include background-image("share-twitter.png");
      }
    }

    .a2a_button_facebook {
      @include background-image("share-facebook.png");
      margin-right: 5px;

      &:hover {
        @include background-image("share-facebook.png");
      }
    }

    .a2a_button_line {
      @include background-image("share-line.png");

      &:hover {
        @include background-image("share-line.png");
      }
    }
  }

  &__img {
    margin-bottom: 1.5rem;
    @include screen-tb {
      margin-bottom: calc(2.5rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 2.5rem;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  // 記事
  &__cont {
    font-size: 1.4rem;
    border-bottom: 1px solid $gray;

    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
      padding-bottom: calc(6.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
      padding-bottom: 6.5rem;
    }

    p {
      line-height: 1.8;
      margin-bottom: 2.5rem;

      @include screen-tb {
        line-height: 2;
        margin-bottom: calc(2rem * 0.6);
      }

      @include screen-pc {
        margin-bottom: 2rem;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-bottom: 2.5rem;

      @include screen-tb {
        margin-bottom: calc(2rem * 0.6);
      }

      @include screen-pc {
        margin-bottom: 2rem;
      }
    }

    // h2 {
    //   font-weight: $bold;
    //   background-color: $blue;
    //   color: $white;
    //   font-size: 1.6rem;
    //   padding: 1rem;
    //   margin-bottom: 1.5rem;

    //   @include screen-tb {
    //     font-size: calc(2rem * 0.6);
    //     padding: calc(1rem * 0.6);
    //     margin: calc(5rem * 0.6) 0 calc(2rem * 0.6);
    //   }

    //   @include screen-pc {
    //     font-size: 2rem;
    //     padding: 1rem;
    //     margin: 5rem 0 2rem;
    //   }
    // }

    // h3 {
    //   font-weight: $bold;
    //   font-size: 1.6rem;
    //   border-bottom: 3px solid #cbcbcb;
    //   margin: 1.5rem 0;

    //   @include screen-tb {
    //     font-size: calc(1.8rem * 0.6);
    //     border-bottom: 3px solid #cbcbcb;
    //     margin: calc(3.5rem * 0.6) 0 calc(1.5rem * 0.6);
    //     padding: 0 0 calc(0.5rem * 0.6);
    //   }

    //   @include screen-pc {
    //     font-size: 1.8rem;
    //     border-bottom: 3px solid #cbcbcb;
    //     margin: 3.5rem 0 1.5rem;
    //     padding: 0 0 0.5rem;
    //   }
    // }

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: $bold;
      padding: 1rem;

      @include screen-tb {
        padding: calc(1rem * 0.6);
        margin-bottom: calc(2rem * 0.6);
      }

      @include screen-pc {
        padding: 1rem;
        margin-bottom: 2rem;
      }
    }

    // img {
    //   width: 100%;
    //   max-width: 100%;
    //   height: auto;
    //   display: block;

    //   @include screen-tb {
    //     margin: calc(3rem * 0.6) 0;
    //   }

    //   @include screen-pc {
    //     margin: 3rem 0;
    //   }
    // }
  }

  &__prev-next {
    margin-bottom: 2rem;
    @include screen-tb {
      margin-bottom: calc(2rem * 0.6);
    }

    @include screen-pc {
      margin-bottom: 2rem;
    }
  }
  &__prev-next-flex {
    align-items: center;
    padding: 1rem 0;

    &:nth-of-type(1) {
      border-bottom: 1px solid $gray;
    }

    @include screen-tb {
      padding: calc(1.5rem * 0.6) 0;
    }

    @include screen-pc {
      padding: 1.5rem 0;
    }

    a {
      font-size: 1.2rem;

      &:hover {
        opacity: 0.5;
      }

      @include screen-tb {
        font-size: calc(1.4rem * 0.6);
      }

      @include screen-pc {
        font-size: 1.4rem;
      }
    }
  }

  &__prev-next__ttl {
    flex-shrink: 0;
    position: relative;
    font-size: 1.2rem;
    padding-left: 3.5rem;

    @include screen-tb {
      font-size: calc(1.4rem * 0.6);
      padding-left: calc(3.5rem * 0.6);
    }

    @include screen-pc {
      font-size: 1.4rem;
      padding-left: 3.5rem;
    }

    &:before {
      @include background-image("arrow-back.svg");
      @include absolute($top: 50%, $left: 0rem);
      transform: translateY(-50%) scale(-1, 1);

      content: "";
      display: block;
      width: 1.8rem;
      height: 1.3rem;
    }
  }

  &__back {
    width: 100%;
    height: 5rem;
    border: 2px solid $black;
    text-align: center;
    display: block;
    line-height: 4.8rem;
    position: relative;

    &:hover {
      opacity: 0.5;
    }

    @include screen-tb {
      height: calc(6rem * 0.6);
      line-height: calc(5.8rem * 0.6);
      font-size: calc(1.6rem * 0.6);
    }

    @include screen-pc {
      height: 6rem;
      line-height: 5.8rem;
      font-size: 1.6rem;
    }

    &:before {
      @include background-image("arrow-back.svg");
      @include absolute($top: 50%, $left: 3rem);
      transform: translateY(-50%);
      content: "";
      display: block;
      width: 1.8rem;
      height: 1.3rem;
    }
  }
}
