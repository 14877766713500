/*--------------------------------------------
 found > var
---------------------------------------------*/

$pc: 1280px;
$tb: 768px;
$sp: 412px;

/*--------------------------------------------
 Color - カラー
---------------------------------------------*/

$white:#fff;
$black:#000;
$gray:#ababab;
$blue: #202387;
$red:#ae021a;
$orange: #ff3800;

/*--------------------------------------------
 Easing - イージング
---------------------------------------------*/

// ▼ クラベリア
$menu-cover-easing: cubic-bezier(0.215, 0.61, 0.355, 1);
$gnav-easing: cubic-bezier(0.645, 0.045, 0.355, 1);
// ▼ codepen
$nav-button-easing: cubic-bezier(0.645, 0.045, 0.355, 1);

/*--------------------------------------------
 Path - パス
---------------------------------------------*/

$path-images: '../img/' !default;

