/*--------------------------------------------
 found > font
---------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Noto+Serif+JP:wght@700&family=Oswald:wght@400;600&display=swap');


$regular:400;
$bold:700;
$semi-bold:600;

@font-face {
  font-family: "ser-medium";
  src: url("../../fonts/Serpentine-MediumOblique.otf") format('opentype');
}

@font-face {
  font-family: "ser-bold";
  src: url("../../fonts/Serpentine-BoldOblique.otf") format('opentype');
}

// アルファベット
$en-med: "ser-medium", serif;
$en-bol: "ser-bold", serif;
$num: 'Oswald', sans-serif;

// 日本語
$jp-min: "游明朝", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho",
  "Noto Serif JP", serif;

$jp-goth: "Noto Sans JP", "メイリオ",sans-serif;

