// back-to-top
.back-to-top {
  width: 100%;
  height: 0.7rem;
  background-color: $red;
  position: relative;

  &__arrow {
    width: 9rem;
    height: 3rem;
    border-radius: 0.3rem 0.3rem 0 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $red;
    cursor: pointer;

    img {
      @include center(xy);
      width: 1.6rem;
      height: 1.1rem;
    }
  }
}
